<template>
    <div>
        <sui-modal 
            size="small" 
            id="signaturePadModal"
            :open="isOpen" 
        >
            <sui-modal-header v-if="modalHeader" class="fiberlan-modal-header"> {{ modalHeader }} </sui-modal-header>
            <sui-modal-content class="content" style="max-height: 65vh;">
                <VueSignaturePad
                    width="100%"
                    height="200px"
                    id="signature"
                    ref="signaturePad"
                    :options="signatureOptions"
                />
                <!-- <img :src="dataURL" alt="signature" /> --> <!-- This is a preview of the signature for testing purposes -->
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button circular class="fiberlan-button-inverted" @click.prevent="clickAway(true)"> {{ cancelLabel }} </sui-button>
                <sui-button circular class="fiberlan-button-inverted" @click.prevent="undoSignature" :disabled="this.signaturePadIsEmpty"> {{ undoLabel }} </sui-button>
                <sui-button circular class="fiberlan-button-inverted" @click.prevent="clearSignature" :disabled="this.signaturePadIsEmpty"> {{ clearLabel }} </sui-button>
                <sui-button circular class="fiberlan-button" @click.prevent="saveSignature" :disabled="this.signaturePadIsEmpty"> {{ saveLabel }} </sui-button>
            </sui-modal-actions>
        </sui-modal>
    </div>
</template>

<script>

export default {
    name: 'OnScreenSignaturePad',
    // components: {
    //     VueSignaturePad,
    // },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        cancelLabel: {
            type: String,
            default: 'Annuller',
        },
        undoLabel: {
            type: String,
            default: 'Fortryd',
        },
        clearLabel: {
            type: String,
            default: 'Ryd',
        },
        saveLabel: {
            type: String,
            default: 'Gem',
        },
        modalHeader: {
            type: String,
            default: '',
        },

    },
    data() {
        return {
            signatureOptions: {
                penColor: 'black',
                dotSize: 2,
                onBegin: () => {
                    // this.$refs.signaturePad.resizeCanvas();
                    // console.log("begin drawing");
                },
                onEnd: () => {
                    // console.log("end drawing");
                    this.signaturePadData = this.$refs.signaturePad.saveSignature();
                },
            },
            signaturePadData: {data: undefined, isEmpty: true},
            dataURL: null,
            // defaultOptions: {
            //     dotSize: (0.5 + 2.5) / 2,
            //     minWidth: 0.5,
            //     maxWidth: 2.5,
            //     throttle: 16,
            //     minDistance: 5,
            //     backgroundColor: 'rgba(0,0,0,0)',
            //     penColor: 'black',
            //     velocityFilterWeight: 0.7,
            //     onBegin: () => {},
            //     onEnd: () => {}
            // }
        };
    },
    methods: {
        clickAway(deep = false) {
            this.$emit('close', deep);
        },
        undoSignature() {
            this.$refs.signaturePad.undoSignature();
            this.signaturePadData = this.$refs.signaturePad.saveSignature();
        },
        clearSignature() {
            this.$refs.signaturePad.clearSignature();
            this.signaturePadData = this.$refs.signaturePad.saveSignature();
        },
        lockSignaturePad() {
            console.log('Locking signature pad')
            this.$refs.signaturePad.lockSignaturePad();
        },
        openSignaturePad() {
            console.log('Opening signature pad')
            this.$refs.signaturePad.openSignaturePad();
            this.resizeCanvas();
        },
        saveSignature() {
            // console.log(this.$refs.signaturePad.saveSignature('image/svg+xml'))
            this.signaturePadData = this.$refs.signaturePad.saveSignature();
            let data = this.$refs.signaturePad.signaturePad.toDataURL('image/svg+xml');
            this.dataURL = data;
            console.log(data);
            // let { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            // console.log(isEmpty, data);
            // if (!isEmpty) {
            //     this.toggleModalOpen();
            //     this.$emit('signed', data);
            // }
        },
        resizeCanvas() {
            setTimeout(() => {
                this.$refs.signaturePad.resizeCanvas();
            }, 300);
        }
    },
    computed: {
        signaturePadIsEmpty() {
            return this.signaturePadData.isEmpty;
        },
        currentMedia() {
            return this.$root.$children[0].currentMedia
        }
    },
    watch: {
        isOpen(val) {
            if (val) {
                this.openSignaturePad();
            } else {
                this.lockSignaturePad();
                this.clearSignature();
            }
        },
        currentMedia() {
            console.log('Media changed')
            this.resizeCanvas();
        },
    }
};
</script>

<style>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #d69d56, #d69d56);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

</style>