<template>
    <div>
        <sui-modal
            size="small"
            id="editImageModal"
            :open="isOpen"
        >
            <sui-modal-header>
                {{ modalHeader }}
                <sui-button
                    size="mini"
                    title="Annullér"
                    floated="right"
                    style="padding: 9.87755px; margin-left: 10px;" 
                    @click="clickAway()"
                ><i class="fa-solid fa-xmark"></i></sui-button>
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70svh;">
                <sui-form>
                    <sui-image style="margin-bottom: 10px;" :src="imageURL" fluid rounded/>
                    <sui-button
                        size="mini"
                        class="actions-buttons"
                        title="Upload eller tag billede med telefonens kamera"
                        @click.prevent="triggerFileInput"
                    >
                        <i class="fa-solid fa-camera"></i>
                        Upload eller tag billede
                    </sui-button> 
                    <input ref="embedFileInput" type="file" hidden @change="setFileUpload"/>

                    <sui-button
                        size="mini"
                        class="actions-buttons"
                        title="Vælg billede fra biblioteket af allerede uploadede billeder"
                        @click.prevent="openImageSelectorModal()"
                    >
                        <i class="fa-solid fa-images"></i>
                        Vælg fra bibliotek
                    </sui-button> 

                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <label> Hvordan skal billedet vises i PDF'en? </label>
                            <sui-dropdown
                                selection
                                search
                                :options="renderingOptions"
                                v-model="renderingOption"
                            ></sui-dropdown>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing"> 
                        <sui-form-field>
                            <sui-checkbox 
                                label="Inkluder billede i PDF" 
                                v-model="includeInPdf"
                            ></sui-checkbox>
                        </sui-form-field>
                    </div>
                    <div class="form-field-spacing">
                        <sui-form-field>
                            <label> Billedtekst </label>
                            <textarea 
                                placeholder="Beskriv billedet" 
                                v-model="imageText" 
                            ></textarea>
                        </sui-form-field>
                    </div>
                                   

                </sui-form>
            </sui-modal-content>

            <sui-modal-actions>
                <sui-button
                    positive 
                    size="mini"
                    title="Gem billedet"
                    style="padding: 9.87755px;" 
                    @click.prevent="onSave()"
                ><i class="fa-solid fa-floppy-disk"></i></sui-button>

                <sui-button
                    negative
                    size="mini"
                    title="Slet billedet"
                    style="padding: 9.87755px;" 
                    @click.prevent="onDelete()"
                    :disabled="deleteButtonDisabled"
                ><i class="fa-solid fa-trash"></i></sui-button>
            </sui-modal-actions>

        </sui-modal>
       
        <image-selector-modal
            :isOpen="imageSelectorModalOpen"
            :baseFilePath="imageSelectorBaseFilePath"
            @select-image="selectImage"
            @close="closeImageSelectorModal"
        />
    </div>                             
</template>

<script>
import EventBus from '@/EventBus'
import swal from 'sweetalert'

import { storageRef } from '@/firebase'
import { JSON2HTML } from '@/modules/FormToPdf/mixins/JSON2HTMLMixin.js'
import { Mime } from '@/lib/helpers/mime.js'
import ImageSelectorModal from '@/components/Images/ImageSelectorModal.vue'
    
export default {

        mixins: [
            // Mixin,
            Mime,
            EventBus,
            JSON2HTML,
        ],

        components: {
            ImageSelectorModal
        },

        props: {
            isOpen: Boolean,
            form: Object,
            image: Object,
            indices: Object,
            path: String,
        },

        data() {
            return {
                imagePath: '',
                imageURL: '',
                imageText: '',
                imageFound: false,
                renderingOption: 'largeImage',
                includeInPdf: true,

                renderingOptions: [
                    { text: 'Stort billede (Billedtekst under)', value: 'largeImage' },
                    { text: 'Lille billede (Billedteskt til højre for)', value: 'smallImage' },
                    { text: 'Stort billede uden billedtekst', value: 'largeImageWithoutText' },
                ],

                // File upload
                fileFormSuccess: false,
                showProgress: false,
                fileUpload: null,

                // Image selector
                imageSelectorModalOpen: false,
            }
        },

        computed: {
            user() {
                return this.$root.$children[0].user
            },

            currentMedia(){
                return this.$root.$children[0].currentMedia
            },

            modalHeader(){
                if (this.image) {
                    return 'Rediger Billede'
                }
                return 'Tilføj Billede'
            },

            deleteButtonDisabled(){
                if (this.image) {
                    return false
                }

                if (this.imagePath || this.imageURL) {
                    return false
                }

                return true
            },

            imageSelectorBaseFilePath(){
                return `${this.path}/Images/`
            }
        },

        methods: {
            clickAway() {
                this.imagePath = ''
                this.imageURL = ''
                this.imageText = ''
                this.imageFound = false
                this.renderingOption = 'largeImage'
                this.includeInPdf = true
                this.fileUpload = null
                EventBus.$emit('event-edit-image-click-away');
            },

            triggerFileInput(){
                this.$refs.embedFileInput.click()
            },

            async setFileUpload(e){
                this.fileFormSuccess = false
                this.showProgress = false
                var files = e.target.files || e.dataTransfer.files;
                if (!files.length) {
                    this.fileUpload = null
                    return false
                }
                this.fileUpload = files[0]
                this.imageURL = await this.encodeFile(this.fileUpload)
                // console.log("file", base64Content)
                return true
            },

            async onSave(){
                console.log('saving')
                
                EventBus.$emit('function-activity', { functionName: 'ImageEditorModal_onSave', isActive: true })

                if (this.fileUpload) {

                    // Upload image to storage
                    let fileName = this.fileUpload.name
                    
                    const expectedExtension = this.getExtenstionByMimeType(this.fileUpload.type)
                    const fileExtension = this.getFileExtension(fileName)
                    if (fileExtension.toLowerCase() != expectedExtension) {
                        fileName += `.${expectedExtension}`
                    }

                    let metadata = {
                        contentType: this.fileUpload.type,
                        customMetadata: { //Custom metadata for uploads to Firebase storage MUST be a simple object with no nesting
                            'uploadedBy': this.user.email
                        }
                    }

                    let base64Content = this.imageURL
                    base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)


                    let imageFilePath = `${this.path}/Images/${fileName}`
                    console.log("image file path", imageFilePath)
                    let newFileRef = storageRef.child(imageFilePath)
                    console.log("new file ref", newFileRef)
                    await newFileRef.putString(base64Content, 'base64', metadata)

                    this.imagePath = imageFilePath
                    if (this.image){
                        this.imageURL = await this.getImageURL(this.imagePath)
                    }
                    this.imageFound = true
                    
                    

                    console.log("new image to upload")
                }

                let imageData = {
                    imagePath: this.imagePath,
                    imageURL: this.imageURL,
                    imageText: this.imageText,
                    imageFound: this.imageFound,
                    renderingOption: this.renderingOption,
                    includeInPdf: this.includeInPdf,
                }

                if (this.image) {
                    EventBus.$emit('event-image-editor-update', { imageData, indices: this.indices })
                    console.log('update-image', imageData)
                } else {
                    EventBus.$emit('event-image-editor-add', { imageData, indices: this.indices })
                    console.log('add-image', imageData)
                }
                this.clickAway()

                EventBus.$emit('function-activity', { functionName: 'ImageEditorModal_onSave', isActive: false })
            },

            async onDelete(){
                let shouldDelete = await swal({
                    title: "Er du sikker?",
                    text: "Billedet vil blive slettet permanent",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })

                if (!shouldDelete) {
                    return
                }
                EventBus.$emit('event-image-editor-delete', { indices: this.indices })
                this.clickAway()
            },

            async prePopulateForm(){
                console.log("prePopulateForm")
                console.log("Image: ", this.image)
                console.log("Indices: ", this.indices)

                if (this.image) {
                    this.imagePath = this.image.imagePath
                    this.imageURL = this.image.imageURL
                    this.imageText = this.image.imageText
                    this.imageFound = this.image.imageFound
                    this.renderingOption = this.image.renderingOption
                    this.includeInPdf = this.image.includeInPdf
                } else {
                    this.imagePath = 'FormToPdfAssets/Placeholder_view_vector.jpg'
                    this.imageURL = await this.getImageURL(this.imagePath)
                }
            },

            async encodeFile(file){
                EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: true})
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => resolve (reader.result)
                    reader.onerror = error => reject(error)
                })
                let encodedFile = await toBase64(file).catch(e => Error(e))
                if(encodedFile instanceof Error) {
                    console.log('Error encoding file: ',encodedFile.message)
                    EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: false})
                    return
                }
                EventBus.$emit('function-activity', {functionName: 'ImageEditor_encodeFile', isActive: false})
                return encodedFile
            },

            openImageSelectorModal(){
                this.imageSelectorModalOpen = true
            },

            closeImageSelectorModal(){
                this.imageSelectorModalOpen = false
            },

            async selectImage(imageRef){
                this.imageURL = await imageRef.getDownloadURL()
                this.imagePath = imageRef.fullPath
            },


        },

        watch: {
        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        },
    }

    }
</script>

<style scoped>
    .image-show-full {
        width: 700px;
        /* height: 400px; */
        /* border: 1px solid black; */
        /* margin-right: 10px; */
    }

    .image-show-full img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .form-field-spacing {
        margin-top: 3px;
        margin-bottom: 3px;
    }

    .actions-buttons {
        margin-top: 5px !important;
    }
</style>