<template>
    <div class="container-fluid migrations">
        <div class="row">
            <div class="col-sm-6">
                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        HUBsAddCoordinatesMigration
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <HUBsAddCoordinatesMigration
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion>

                <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        AppointmentProjectTasksImportMigration
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <AppointmentProjectTasksImportMigration
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        AppointmentType
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <AppointmentTypeAndRoles
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        Appointments ReferenceId
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <ReferenceIdOnAppointments
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        UnitWork Add Address & referenceId
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <UnitWorkToIncludeAddress
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        ProjectAppointmentsToGlobalMigration
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <ProjectAppointmentsToGlobalMigration
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        AddLinkedProjectToTicketAppointments
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <AddLinkedProjectToTicketAppointmentsMigration
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        AddLinkedProjectToTicketUnitWorks
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <AddLinkedProjectToTicketUnitWorksMigration
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <!-- <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        ProjectInstallationTypeToString
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <ProjectInstallationTypeToString
                            @log="addToConsole"
                        />
                    </sui-accordion-content>
                </sui-accordion> -->

                <sui-accordion exclusive styled>
                    <sui-accordion-title>
                        <sui-icon name="dropdown" />
                        Genskab LPC'er for Erhversprojektet
                    </sui-accordion-title>
                    <sui-accordion-content>
                        <RestoreLPCsErhverv
                            @log="addToConsole"
                        />
                    </sui-accordion-content>

                </sui-accordion>

            </div>
            <div class="col-sm-6">
                <sui-segment class="full-height">
                    <div class="console">
                        <div class="console-segment">
                            <template v-for="(segment, index) in this.consoleSegmentsReversed">
                                <div class="segment-item" :key="index">{{ segment }}</div>
                            </template>
                        </div>
                    </div>
                    <p></p>
                    <sui-button @click="clearConsole" negative>
                        <sui-icon name="dont" />
                        Clear
                    </sui-button>
                </sui-segment>
            </div>
        </div>
    </div>
</template>
<script>
// import AppointmentProjectTasksImportMigration from '../../components/Admin/Migrations/210312-AppointmentProjectTasks'
// import ProjectAppointmentsToGlobalMigration from '../../components/Admin/Migrations/210409-ProjectAppointmentsToGlobal'
// import HUBsAddCoordinatesMigration from '../../components/Admin/Migrations/HUBsAddCoordinates'
// import AddLinkedProjectToTicketAppointmentsMigration from '../../components/Admin/Migrations/211001-AddLinkedProjectToTicketAppointmentsMigration.vue'
// import AddLinkedProjectToTicketUnitWorksMigration from '../../components/Admin/Migrations/230309-AddLinkedProjectToTicketUnitWorkMigration.vue'
// import ProjectInstallationTypeToString from '../../components/Admin/Migrations/211216-ProjectInstallationTypeArr.vue'
// import UnitWorkToIncludeAddress from '@/components/Admin/Migrations/220629-UnitWorkToIncludeAddress.vue'
// import ReferenceIdOnAppointments from '../../components/Admin/Migrations/220706-ReferenceIdOnAppointments.vue'
// import AppointmentTypeAndRoles from '../../components/Admin/Migrations/230321-AppointmentTypeAndRoles.vue'
// import ContactsAndProjectRefs from '../../components/Admin/Migrations/241031-ContactsAndProjectRefs.vue'
import RestoreLPCsErhverv from '../../components/Admin/Migrations/241104-UndoLPC.vue'


export default {
    name: 'AdminMigrations',

    components: {
    // AppointmentProjectTasksImportMigration,
    // HUBsAddCoordinatesMigration,
    // ProjectAppointmentsToGlobalMigration,
    // AddLinkedProjectToTicketAppointmentsMigration,
    // AddLinkedProjectToTicketUnitWorksMigration,
    // ProjectInstallationTypeToString,
    // UnitWorkToIncludeAddress,
    // ReferenceIdOnAppointments,
    // AppointmentTypeAndRoles,
    RestoreLPCsErhverv
},

    data() {
        return {
            consoleSegments: []
        }
    },

    computed: {
        consoleSegmentsReversed() {
            const segments = JSON.parse(JSON.stringify(this.consoleSegments))
            return segments.reverse()
        }
    },

    methods: {
        addToConsole(message) {
            if (typeof message == 'object') {
                message = JSON.stringify(message)
            }
            this.consoleSegments.push(message)
        },

        clearConsole() {
            this.consoleSegments = []
        }
    },

    mounted() {
        // for (let i = 0; i <= 50; i++) {
        //     this.addToConsole(`Hello, world ${i}`)
        // }
    }
}
</script>
<style scoped>
.full-height {
    height: calc(100vh - 235px);
    padding: 0 !important;
}
.console {
    white-space: pre-wrap;
    background: #dddddd;
    font-family: monospace;
    font-size: 12px;
    height: 100%;
    padding: 10px;
}
.console-segment {
    /*min-height: 10vw;
    max-height: 50vw;*/
    height: 100%;
    /*height: 50vw;*/
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
}
.console-segment > * {
    flex-shrink: 0;
}
</style>
<style>
.migrations .ui.label {
    margin-bottom: 2px;
    margin-top: 2px;
}
</style>
