import { db } from '@/firebase.js'
import { mapGetters } from 'vuex'

export const ClientUpdateCacheMixin = {
    data() {
        return {
            cacheUpdateInterval: null,
            cacheDocumentId: 'pilotBiTasksAndTickets',

            defaultTtl: 5 * 60 * 1000, // 5 minutes
            defaultTtlRequest: 2 * 60 * 1000, // 2 minutes

            cacheMetaDocument: null,
        }
    },

    computed: {
        ...mapGetters({
            enableCache: 'useCache',
        }),
    },

    methods: {
        startUpdateTaskCache() {
            if (this.cacheUpdateInterval || !this.enableCache) {
                return;
            }

            let updateInterval = 10000;
            updateInterval += Math.floor(Math.random() * 1000);

            console.log("Starting cache update task with interval: ", updateInterval);
            this.handleCacheUpdate();
            this.cacheUpdateInterval = setInterval(async ()=> {
                this.handleCacheUpdate();
            }, updateInterval);
        },

        stopUpdateTaskCache() {
            if (this.cacheUpdateInterval){
                console.log("Stopping cache update task");
                clearInterval(this.cacheUpdateInterval);
                this.cacheUpdateInterval = null;
            }
        },

        async requestUpdate(mode = 'maintenance') {
            let now = new Date();
            let timeZoneOffset = now.getTimezoneOffset(); // Timezone offset in minutes
            let timestamp = now.toISOString();

            let updateDoc = {
                updateRequested: timestamp,
                timeZoneOffset: timeZoneOffset,
            }

            if (mode == 'force update') {
                let epoch = new Date(0).toISOString();
                updateDoc.updated = epoch;
            }

            if (mode == 'force overwrite') {
                updateDoc = this.cacheMetaDocument;
                updateDoc.updateRequested = timestamp;
                updateDoc.timeZoneOffset = timeZoneOffset;
                delete updateDoc.updated;
            }

            if (mode == 'full restart') {
                // TODO: Implement full restart
                // Await delete cache document and all related documents
                // Await create new cache document
                // update with new request
            }

            // console.log("Requesting cache update with mode: ", mode);
            await db.collection('cacheData').doc(this.cacheDocumentId).update(updateDoc);
        },

        async handleCacheUpdate() {
            this.cacheMetaDocument = await this.getCacheMetaDocument();

            if (!this.cacheMetaDocument.expires && !this.cacheMetaDocument.updateRequested) {
                // console.log("Cache document has expired, updating cache (first time init)");
                this.requestUpdate();
                return;
            }

            let expires = new Date(0);
            if (this.cacheMetaDocument.expires) {
                expires = new Date(this.cacheMetaDocument.expires);
            }

            if (expires < new Date()) { // Cache has expired
                let updateRequested = new Date(this.cacheMetaDocument.updateRequested);
                
                // Check if update was requested less than 2 minutes ago
                let now = new Date();
                let diff = now - updateRequested;

                // let ttlRequestMinutes = this.cacheMetaDocument.ttlRequest / 60000;
                if (diff < this.cacheMetaDocument.ttlRequest) {
                    // console.log(`Update was requested less than ${ttlRequestMinutes} ago, skipping update`);
                    return;
                }
                // console.log("Cache document has expired, updating cache");
                this.requestUpdate();
            } else {
                // console.log("Cache document has not expired, skipping update");
            }
        },

        async getCacheMetaDocument() {
            let retrievedDoc = await db.collection('cacheData').doc(this.cacheDocumentId).get();

            if (!retrievedDoc.exists) {

                console.log("Cache document does not exist, creating new one");
                let timestamp = new Date();
                let timeZoneOffset = timestamp.getTimezoneOffset(); // Timezone offset in minutes

                let newDoc = {
                    ttl: this.defaultTtl,
                    ttlRequest: this.defaultTtlRequest,
                    timeZoneOffset: timeZoneOffset,
                };

                await db.collection('cacheData').doc(this.cacheDocumentId).set(newDoc);
                return newDoc;
            }

            return retrievedDoc.data();
        }
    }
}