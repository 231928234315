<template>
    <div>
        <sui-modal
            size="small" 
            id="selectTemplateActionModal"
            :open="isOpen" 
            v-on:clickAwayModal="clickAway"
        >
            <sui-modal-header>
                Vælg en handling
            </sui-modal-header>

            <sui-modal-content scrolling style="max-height: 70svh;">
                <sui-form>
                    <sui-form-field>
                        <sui-checkbox radio
                            label="Opret en ny skabelon"
                            name="action"
                            value="create"
                            v-model="action"
                        />
                    </sui-form-field>
                    <sui-form-field>
                        <sui-checkbox radio
                            label="Rediger en eksisterende skabelon"
                            name="action"
                            value="edit"
                            v-model="action"
                            @click="getTemplates()"
                        />
                    </sui-form-field>

                    <div v-if="action === 'edit'" class="field">
                        <label>Select a template to edit:</label>
                        <sui-dropdown 
                            placeholder="Vælg en skabelon"
                            selection 
                            v-model="selectedTemplate"
                            :options="templateOptions"
                        />
                    </div>

                    <div 
                        v-if="action == 'edit'"
                        :class="classes(
                            currentMedia.type == 'Desktop' && 'placeholder-desktop',
                            currentMedia.type == 'Mobile' && 'placeholder-mobile'
                        )
                    "></div>

                </sui-form>
            </sui-modal-content>

            <sui-modal-actions>
                <sui-button 
                    @click="clickAway"
                >
                    Annuller
                </sui-button>
                <sui-button 
                    primary 
                    :disabled="submitDisabled"
                    @click="submit"
                >
                    OK
                </sui-button>
            </sui-modal-actions>

        </sui-modal>
    </div>
</template>
<script>

import { Mixin } from '../../../lib/Mixins/mixin'
import { db } from '../../../firebase'

export default {
    props: {
        isOpen: Boolean,
    },

    mixins: [
        Mixin,
        db
    ],

    data() {
        return {
            action: '',
            selectedTemplate: null,
            templates: [],
        }
    },

    computed: {
        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        templateOptions(){
            let options = []
            options = this.templates.map(template => {
                return {
                    text: template.templateName,
                    value: template.id,
                }
            })
            return options
        },

        submitDisabled(){
            if (this.action === '') {
                return true
            }

            if (this.action === 'edit' && this.selectedTemplate === null) {
                return true
            }

            return false
        },
    },

    methods: {
        clickAway(){
            this.action = ''
            this.selectedTemplate = null
            this.templates = []
            this.$emit('close');
        },

        submit(){
            let activeTemplate = null
            if (this.action === 'edit') {
                activeTemplate = this.templates.find(template => template.id == this.selectedTemplate)
            }

            this.$emit('template-action', activeTemplate);
            this.clickAway();
        },

        async getTemplates(){
            if (this.templates.length > 0) return

            await this.$bind('templates', db.collection('FormToPdfTemplates').orderBy('templateName', 'asc'))
        },
    },
}
</script>

<style>
.placeholder-desktop {
    height: 250px;
}
.placeholder-mobile {
    height: 150px;
}
</style>