import Vue from 'vue'
import VueRouter from 'vue-router'
import swal from 'sweetalert'

import { auth, db } from './firebase'
import store from './store'

// Import Components
import LoginView from './pages/LoginView'
import ProjectListView from './pages/Project/List'
import ProjectCreateView from './pages/Project/Create'
import ProjectWrapper from './pages/Project/ProjectWrapper'
import ProjectDetail from './pages/Project/Detail'
import ProjectBooking from './pages/Project/Booking'
import ProjectInstallationList from './pages/Project/InstallationList'
import InstallationDetail from './pages/Project/InstallationDetail'
import ProjectTimelinePage from './pages/Project/TimelinePage'
import UnitWorkList from './pages/Project/UnitWorkList'
import TaskDetail from './pages/Project/TaskDetail'
// import ProjectBlowingCabinets from './pages/Project/BlowingCabinets'
// import ProjectSplicingCabinets from './pages/Project/SplicingCabinets'
import ProjectCabinetDashboard from './pages/Project/CabinetDashboard'
import AvailableSonWin from './pages/Project/AvailableSonWin'
import AddressSearch from './pages/AddressSearch'
import Feedback from './pages/Feedback'
import SalaryCheckPage from './pages/Salary/SalaryCheckPage.vue'
import IllnessStatsPage from './pages/Salary/IllnessStatsPage.vue'
import AdminWrapper from './pages/Admin/AdminWrapper'
import AdminMigrations from './pages/Admin/Migrations'
import AdminWorkers from './pages/Admin/Workers'
import UnitWorkUnitsList from './pages/Admin/UnitWorkUnitsList'
import AdminWorkerDetail from './pages/Admin/WorkerDetail'
import HubsList from './pages/Hubs/List'
import HubDetail from './pages/Hubs/Detail'
import FileList from './pages/Files/List'
import InstallationCheck from './pages/InstallationCheck.vue'
import CleanerLogs from './pages/Public/CleanerLogs'
import DocumentApproval from './pages/Public/CustomerPortal/DocumentApproval.vue'
import IconLegend from './pages/Meta/iconLegend.vue'
// import pdfGenerator from './components/FormToPdf/pdfGenerator.vue'
import UserRoles from './lib/Enums/UserRoles'
import FormToPdfPage from './modules/FormToPdf/pages/FormToPdfPage.vue'

Vue.use(VueRouter)

let routes = [
    // {
    //     path: '*',
    //     redirect: '/login'
    // },
    {
        name: 'Login',
        path: '/login',
        component: LoginView,
        meta: {
            title: 'Log ind - FiberTeam'
        }
    },
    {
        name: 'PublicLog',
        path: '/logs',
        component: CleanerLogs,
        meta: {
            title: 'Logfiler - FiberTeam',
            requiresAuth: false
        }
    },
    {
        name: 'CustomerPortalDocumentApproval',
        path: '/kunde/dokument',
        component: DocumentApproval,
        meta: {
            title: 'Kundeportal - FiberLAN',
            requiresAuth: false
        }
    },
    {
        name: 'ProjectListView',
        path: '/projects',
        component: ProjectListView,
        meta: {
            requiresAuth: true,
            requiresUser: true,
        }
    },
    {
        name: 'ProjectCreateView',
        path: '/projects/create',
        component: ProjectCreateView,
        meta: {
            requiresAuth: true,
            requiresUser: true,//TODO: require project editor role
            title: 'Opret projekt'
        }
    },
    {
        name: 'ProjectEditView',
        path: '/projects/edit/:id',
        component: ProjectCreateView,
        meta: {
            requiresAuth: true,
            requiresUser: true,//TODO: require project editor role
            requiresProjectAccess: true,
        }
    },
    {
        name: 'AvailableSonWin',
        path: '/projects/sonwin/available',
        component: AvailableSonWin,
        meta: {
            requiresAuth: true,
            requiresUser: true,//TODO: require project editor role
            title: 'Redigér projekt'
        }
    },
    {
        // name: 'ProjectDetail',
        path: '/projects/:projectIdentifier',
        component: ProjectWrapper,
        meta: {
            requiresAuth: true,
            requiresUser: true,
            requiresProjectAccess: true
        },
        children: [
            {
                name: 'ProjectDetail',
                path: '',
                component: ProjectDetail,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },

            {
                name: 'ProjectBooking',
                path: 'booking',
                component: ProjectBooking,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },
            {
                name: 'ProjectInstallationList',
                path: 'installations',
                component: ProjectInstallationList,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },
            {
                name: 'ProjectTimeline',
                path: 'timeline',
                component: ProjectTimelinePage,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },
            {
                name: 'ProjectCabinetDashboard',
                path: 'cabinet-dashboard',
                component: ProjectCabinetDashboard,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },
            // {
            //     name: 'ProjectBlowingCabinets',
            //     path: 'blowing-cabinets',
            //     component: ProjectBlowingCabinets,
            //     meta: {
            //         requiresAuth: true,
            //         requiresUser: true,
            //     }
            // },
            // {
            //     name: 'ProjectSplicingCabinets',
            //     path: 'splicing-cabinets',
            //     component: ProjectSplicingCabinets,
            //     meta: {
            //         requiresAuth: true,
            //         requiresUser: true,
            //     }
            // },
            {
                name: 'InstallationDetail',
                path: 'installations/:installationId',
                alias: 'timeline/:installationId',
                component: InstallationDetail,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },
            {
                name: 'TaskDetail',
                path: 'installations/:installationId/:taskNum',
                alias: 'timeline/:installationId/:taskNum',
                component: TaskDetail,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },
            {
                name: 'UnitWorkList',
                path: 'unitWork',
                component: UnitWorkList,
                meta: {
                    requiresAuth: true,
                    requiresUser: true,
                    requiresProjectAccess: true
                }
            },
        ]
    },
    // {
    //     path: '/ttprojects/:projectIdentifier',
    //     component: ProjectWrapper,
    //     meta: {
    //         requiresAuth: true,
    //         requiresUser: true,
    //     },
    //     children: [
    //         {
    //             name: 'TtProjectDetail',
    //             path: '',
    //             component: TtProjectDetail,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiresUser: true,
    //             }
    //         },
    //         {
    //             name: 'TtProjectBooking',
    //             path: 'booking',
    //             component: TtProjectBooking,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiresUser: true,
    //             }
    //         },
    //         {
    //             name: 'TtUnitWorkList',
    //             path: 'unitWork',
    //             component: UnitWorkList,
    //             meta: {
    //                 requiresAuth: true,
    //                 requiresUser: true,
    //             }
    //         },
    //     ]
    // },
    {
        path: '/',
        redirect: '/projects'
    },
    {
        name: 'HubsListView',
        path: '/hubs',
        component: HubsList,
        meta: {
            requiresAuth: true,
            requiresUser: true, //TODO: require user role and EFB_API_USER role
            title: 'Accesshuse - FiberTeam'
        }
    },
    {
        name: 'HubDetail',
        path: '/hubs/:hubid',
        component: HubDetail,
        meta: {
            requiresAuth: true,
            requiresUser: true,//TODO: require user role and EFB_API_USER role
        },
    },
    {
        name: 'InstallationCheck',
        path: '/installationCheck',
        component: InstallationCheck,
        meta: {
            requiresAuth: true,
            requiresUser: true,//TODO: require user role and EFB_API_USER role
            title: 'Installationsopslag - FiberTeam'
        }
    },
    {
        name: 'Files',
        path: '/files',
        component: FileList,
        meta: {
            requiresAuth: true,
            requiresUser: true,//TODO: require USER role
            title: 'Filer - FiberTeam'
        },
    },
    {
        name: 'AddressSearch',
        path: '/addresssearch',
        component: AddressSearch,
        meta: {
            requiresAuth: false, //TODO: require USER role
            title: 'Adressesøgning - FiberTeam'
        }
    },
    {
        name: 'Feedback',
        path: '/feedback',
        component: Feedback,
        meta: {
            requiresAuth: true, //TODO: Delete this page
            title: 'Feedback - FiberTeam'
        }
    },
    {
        name: 'SalaryCheck',
        path: '/hourcheck',
        component: SalaryCheckPage,
        meta: {
            requiresAuth: true, // TODO: require USER role
            title: 'Løntime-tjek - FiberTeam'
        }
    },
    {
        name: 'IllnessStats',
        path: '/sygdom',
        component: IllnessStatsPage, //TODO: require ILLNESS_STATISTICS role
        meta: {
            requiresAuth: true,
            title: 'Sygdoms-statistik - FiberTeam'
        }
    },
    {
        name: 'FormToPdf',
        path: '/pdfgenerator',
        component: FormToPdfPage,
        meta: {
            requiresAuth: true,
            requiresUser: true,
            requiresProjectAccess: true,
            title: 'PDF Generator - FiberTeam'
        }
    },
    {
        path: '/admin/',
        component: AdminWrapper,
        meta: {
            requiresAuth: true,
            requiresAdmin: true // TODO: require USER_ADMIN role or SUPER_ADMIN role
        },
        children: [
            {
                name: 'AdminMigrations',
                path: 'migrations',
                component: AdminMigrations,
                meta: {
                    requiresAuth: true,
                    requiresSuperAdmin: true, // requries SUPER_ADMIN role
                    title: 'Migrations - FiberTeam'
                }
            },
            {
                name: 'AdminWorkers',
                path: 'workers',
                component: AdminWorkers,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true, // requires USER_ADMIN role
                    title: 'Medarbejdere - FiberTeam'
                }
            },
            {
                name: 'UnitWorkUnits',
                path: 'UnitWorkUnits',
                component: UnitWorkUnitsList,
                meta: {
                    requiresAuth: true,
                    requiresUnitworkAdmin: true, // requires EFB_UNITWORK_ADMIN role
                }
            },
            {
                name: 'AdminWorkerDetail',
                path: 'workers/:WorkerId',
                component: AdminWorkerDetail,
                meta: {
                    requiresAuth: true,
                    requiresAdmin: true // TODO: Delete this page
                }
            },
        ]
    },
    {
        name: 'IconLegend',
        path: '/signaturforklaring',
        component: IconLegend,
        meta: {
            requiresAuth: false, //TODO: require USER role
            title: 'Signaturforklaring'
        }
    },
    // {
    //     name: 'FormToPdfTester',
    //     path: '/form-to-pdf-tester',
    //     component: pdfGenerator,
    //     meta: {
    //         requiresAuth: true,
    //         requiresUser: true,
    //         title: 'Form to PDF Tester'
    //     }
    // },
]

export { routes }

const router = new VueRouter({
    routes,
    mode: 'history'
})


router.beforeEach(async (to, _from, next) => {

    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const requiresUser = to.matched.some(x => x.meta.requiresUser)
    const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
    const requiresSuperAdmin = to.matched.some(x => x.meta.requiresSuperAdmin)
    const requiresUnitworkAdmin = to.matched.some(x => x.meta.requiresUnitworkAdmin)
    let requiresProjectAccess = to.matched.some(x => x.meta.requiresProjectAccess)

    if (requiresAuth && !auth.currentUser) {
        next('/login')
        return // No reason to continue if not authenticated
    }

    let userProfile = store.getters.userProfile
    if (JSON.stringify(userProfile) == JSON.stringify({})){
        console.warn('userProfile was empty')
        let retry = 0
        const maxRetries = 20
        while(JSON.stringify(userProfile) == JSON.stringify({}) && retry < maxRetries){
            await new Promise(resolve => setTimeout(resolve, 100))
            userProfile = store.getters.userProfile
            retry += 1
            // console.log(`Retrying after ${retry}00ms gave userProfile: `,userProfile)
        }

        if (JSON.stringify(userProfile) == JSON.stringify({})){
            console.error(`userProfile was still empty after ${retry} retries`)
        }
    }



    let project = null
    let projectID = null

    // if FormToPDF and no projectID, set requiredProjectAccess to false
    if (to.name == 'FormToPdf' && !to.query.project_id){
        requiresProjectAccess = false
    }

    if (requiresProjectAccess){
        projectID = store.getters.activeProjectId || to.query.project_id || to.params.projectIdentifier
        if (!projectID){
            console.warn('project ID was empty')
            let retry = 0
            const maxRetries = 20
            while(!projectID && retry < maxRetries){
                await new Promise(resolve => setTimeout(resolve, 100))
                projectID = store.getters.activeProjectId || to.query.project_id || to.params.projectIdentifier
                retry += 1
            }
        }
    }
    
    if (projectID){
        project = store.getters.activeProject
        if (JSON.stringify(project) == JSON.stringify({})){
            console.warn('project was empty')
            project = await db.collection('Projects').doc(projectID).get()
            project = project.data()
        }
    }

    const roles = userProfile.Roles || []
    // console.log(roles)

    if (requiresUser && !roles.includes(UserRoles.USER)) {
        if (to.path == '/projects' && roles.includes(UserRoles.HOUR_CHECK)) {
            next('/hourcheck')
        } else {
            swal('Insufficient user access', '', 'error')
        }
    } 
    else if (requiresProjectAccess && !roles.includes(project?.RequiredRole) && project?.RequiredRole) {
        await swal('Du har ikke adgang til dette projekt', 'Mener du at det er en fejl, kontakt din administrator\n på (+45) 63 10 12 58 eller på afd-q@fiberlan.dk', 'error')
        if (roles.includes(UserRoles.SUPER_ADMIN)){
            swal('Du har SUPER_ADMIN rolle', 'Du kan ændre projektets adgangskrav', 'info')
            next()
        }
    }
    else if (requiresUnitworkAdmin && !roles.includes(UserRoles.EFB_UNITWORK_ADMIN)) {
        swal('Insufficient user access', '', 'error')
    }
    else if (requiresAdmin && !roles.includes(UserRoles.USER_ADMIN)) {
        swal('Insufficient user access', '', 'error')
    } else if (requiresSuperAdmin && !roles.includes(UserRoles.SUPER_ADMIN)) {
        swal('Insufficient user access', '', 'error')
    } 

    
    else {
        next()
    }
})

router.afterEach(to => {
    document.title = to.meta?.title || 'FiberTeam'
})

export { router }
