<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Modifies appointments, internal subtasks and unitwork, will add project: 'Frm3yfPD1PGszrHI9j8s' (Erhverv).
            </p>
        </div>

        <strong>Description:</strong>
        <p>
            Will add <sui-label>projectId: 'Frm3yfPD1PGszrHI9j8s'</sui-label> to all LPCs with <sui-label>ProjectInstallationType</sui-label> '9'.
        </p>
        <hr />

        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '@/firebase.js'

export default {
    name: 'RestoreLPCsErhverv',

    data() {
        return {
            isRunning: false,
            dryRun: false,

            projectId:'Frm3yfPD1PGszrHI9j8s',
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message);
        },

        async triggerDry() {
            this.dryRun = true;
            await this.trigger();
        },

        async triggerNormal() {
            this.dryRun = false;
            await this.trigger();
        },

        async trigger() {
            this.isRunning = true;
            this.log(`----------------`);
            this.log(`Starting process`);

            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`);
            }
            this.log('----------------');

            this.log('Getting all appointments');
            let appointments = [];
            await db.collection('Appointments').where('ProjectInstallationType', '==', '9').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let appointment = doc.data();
                    appointment.id = doc.id;
                    appointments.push(appointment);
                });
            });
            this.log(`Got ${appointments.length} appointments`);

            this.log('Looping through appointments');
            let updateCount = 0;
            let failedCount = 0;
            for (let appointment of appointments){
                if (!appointment.LinkedProjects.length){
                    this.log(`No linked projects (appointment): ${appointment.id}`);
                    if (!this.dryRun) {
                        try {
                            await db.collection('Appointments').doc(appointment.id).update({LinkedProjects: [this.projectId]});
                            console.log('Appointment updated:', appointment.id);
                            updateCount++;
                        } catch (error) {
                            console.error('Error updating appointment:', appointment.id, error);
                            failedCount++;
                        } 
                    }
                }
            }
            this.log(`Updated ${updateCount} appointments, ${failedCount} failed`);

            this.log('----------------');
            this.log('Getting all Internal Subtasks');
            let subtasks = [];
            await db.collection('InternalSubTasks').where('ProjectInstallationType', '==', '9').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let subtask = doc.data();
                    subtask.id = doc.id;
                    subtasks.push(subtask);
                });
            });
            this.log(`Got ${subtasks.length} subtasks`);

            this.log('Looping through subtasks');
            updateCount = 0;
            failedCount = 0;
            for (let subtask of subtasks){
                if (!subtask.LinkedProjects.length){
                    this.log(`No linked projects (subtask): ${subtask.id}`);
                    if (!this.dryRun) {
                        try {
                            await db.collection('InternalSubTasks').doc(subtask.id).update({LinkedProjects: [this.projectId]});
                            console.log('Subtask updated:', subtask.id);
                            updateCount++;
                        } catch (error) {
                            console.error('Error updating subtask:', subtask.id, error);
                            failedCount++;
                        } 
                    }
                }
            }
            this.log(`Updated ${updateCount} subtasks, ${failedCount} failed`);
            
            this.log('----------------');
            this.log('Getting all unitworks');
            let unitworks = [];
            await db.collection('UnitWork').where('ProjectInstallationType', '==', '9').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let unitwork = doc.data();
                    unitwork.id = doc.id;
                    unitworks.push(unitwork);
                });
            });
            this.log(`Got ${unitworks.length} unitworks`);

            this.log('Looping through unitworks');
            updateCount = 0;
            failedCount = 0;
            for (let unitwork of unitworks){
                if (!unitwork.LinkedProjects.length){
                    this.log(`No linked projects (unitwork): ${unitwork.id}`);

                    if (!this.dryRun) {

                        try {
                            await db.collection('UnitWork').doc(unitwork.id).update({LinkedProjects: [this.projectId]});
                            console.log('Unitwork updated:', unitwork.id);
                            updateCount++;
                        } catch (error) {
                            console.error('Error updating unitwork:', unitwork.id, error);
                            failedCount++;
                        } 
                    }
                }
            }
            this.log(`Updated ${updateCount} unitworks, ${failedCount} failed`);
            this.log('----------------');

            this.log(`Finished process`);

            this.isRunning = false
        }
    }
}
</script>
<style scoped>
.float.right {
    float: right;
}
</style>