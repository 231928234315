<template>
    <div>
        <sui-modal
            size="small" 
            id="editPDFFormModal"
            :open="isOpen" 
        >
            <sui-modal-header>
                {{ modalHeader }}
                <sui-button
                    size="mini"
                    title="Annullér"
                    floated="right"
                    style="padding: 9.87755px; margin-left: 10px;" 
                    @click="clickAway()"
                ><i class="fa-solid fa-xmark"></i></sui-button>
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70svh;">
                <sui-form :loading="subState == 'loading'">
                    <!-- Disclaimer for locked (edit) -->
                    <div v-if="currentForm && currentForm.state == FormToPDFDtate.AWAITING_APPROVAL" style="margin-bottom: 10px;">
                        <sui-message>
                            <sui-message-header>Formularen er sendt til underskrift</sui-message-header>
                            <p>Formularen er låst og kan ikke redigeres. <br> Hvis du vil redigere formularen, skal du først genåbne den.</p>
                        </sui-message>
                    </div>

                    <!-- FORM ACTIONS -->
                    <div v-if="showActions" style="margin-bottom: 10px;">
                        <label>Handlinger</label><br/>
                        <span v-for="action in actions" :key="action.text">
                            <sui-button 
                                v-if="actionIsVisible(action)"
                                :disabled="actionIsDisabled(action)"
                                size="tiny" 
                                class="actions-buttons" 
                                :key="action.text"
                                @click.prevent="handleAction(action)"
                            >
                                {{ action.text }}
                            </sui-button>
                        </span>
                    </div>

                    <sui-button
                        v-if="currentForm"
                        size="mini"
                        :disabled="state != 'edit' && state != 'edit new'"
                        style="padding: 9.87755px;"
                        @click.prevent="toggleAutoPageBreak()"
                    >
                        <i v-if="!currentForm.dynamicPageBreak" class="fa-regular fa-square"></i>
                        <i v-if="currentForm.dynamicPageBreak" class="fa-regular fa-square-check"></i>
                        &nbsp;{{ "Tilføj automatisk sideskift" }} 
                    </sui-button>


                    
                    <!-- FORM TEMPLATE SELECTION -->
                    <sui-form-field v-if="canSelectTemplate">
                        <label>Vælg formular skabelon</label>
                        <sui-dropdown
                        
                        v-model="selectedFormTemplate"
                        selection
                        :options="templateOptions"
                        placeholder="Vælg en formular skabelon"
                        @input="populateFromTemplate()"
                        ></sui-dropdown>
                    </sui-form-field>

                    <!-- FORM TEMPLATE NAME -->
                    <sui-form-field v-if="createOrEditTemplate && currentForm" required>
                        <label>Navn på skabelonen</label>
                        <input 
                            type="text"
                            v-model="currentForm.templateName"
                            placeholder="Indtast navn på formular skabelonen"
                        />
                    </sui-form-field>
                    <div>
                        <label></label>
                    </div>
                 
                    <div 
                        v-if="state == 'new' && subState == 'select'"
                        :class="classes(
                            currentMedia.type == 'Desktop' && 'placeholder-desktop',
                            currentMedia.type == 'Mobile' && 'placeholder-mobile'
                            )
                    "></div>

                    <!-- FORM CONTENT -->
                    <div v-if="currentForm && currentForm.formComponentGroups">
                    <sui-divider horizontal>
                        Formular indhold
                    </sui-divider>
                    <div class="accordion-spacing" v-for="(group, groupIndex) in currentForm.formComponentGroups" :key="groupIndex">
                        <!-- Collapsable / non-collapsable group -->
                        <sui-accordion>
                            <a 
                                v-if="group.accordion"
                                is="sui-accordion-title" 
                                :active="group.accordionOpen" 
                                @click="toggleAccordion(group)" 
                                style="font-size: larger; font-weight: bold;"
                            >
                                <sui-icon name="dropdown"/>
                                {{ group.accordionOpen ? "" : group.headerText}}
                            </a>
                            <sui-accordion-content :active="group.accordionOpen">
                                <sui-header dividing style="font-size: larger; font-weight: bold;"> {{ group.headerText }} </sui-header> 
                                <edit-template-action-buttons
                                    v-if="createOrEditTemplate && groupIndex != 0"
                                    :groupLength="formComponentGroups.length"
                                    :groupIndex="groupIndex"
                                    :componentIndex="null"
                                    :onDeleteClicked="() => handleDeleteComponentOnTemplate(groupIndex, null)"
                                    :onEditClicked="() => handleEditComponentOnTemplate(groupIndex, null)"
                                    :onCreateClicked="() => handleCreateComponentOnTemplate(groupIndex, null)"
                                    :onMoveDownClicked="() => handleMoveDownComponentOnTemplate(groupIndex, null)"
                                    :onMoveUpClicked="() => handleMoveUpComponentOnTemplate(groupIndex, null)"
                                ></edit-template-action-buttons>

                                <!-- Content -->
                                <div class="component-spacing" v-for="(component, componentIndex) in group.components" :key="componentIndex">
                                    <sui-divider horizontal v-if="createOrEditTemplate">
                                        {{ FormToPdfComponents.getDisplayNameFromCode(component.componentType) }}
                                    </sui-divider>

                                    <sui-button
                                        v-if="!createOrEditTemplate && groupIndex != 0"
                                        :disabled="fieldIsDisabled(component)"
                                        size="mini"
                                        style="padding: 9.87755px; margin-top: -52px;"
                                        @click.prevent="togglePageBreakBefore(component)"
                                    >
                                        <i v-if="!component.pageBreakBefore" class="fa-regular fa-square"></i>
                                        <i v-if="component.pageBreakBefore" class="fa-regular fa-square-check"></i>
                                        &nbsp;{{ "Tvungen sideskift før" }} 
                                    </sui-button>

                                    <div v-for="fieldIndex in component.keys" :key="fieldIndex">
                                        <!-- space -->
                                        <div v-if="component.fields[fieldIndex].formElementType == 'space'"> 
                                            <label></label>
                                        </div>

                                        <!-- field -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'field'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <label> {{ component.fields[fieldIndex].title }} </label>
                                                <input 
                                                :disabled="fieldIsDisabled(component)"
                                                :id="component.fields[fieldIndex].id" 
                                                type="text"
                                                :placeholder="component.fields[fieldIndex].placeholder" 
                                                v-model="component.fields[fieldIndex].value" 
                                            /></sui-form-field>
                                        </div>
                                            
                                        <!-- short text -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'shortText'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <label> {{ component.fields[fieldIndex].title }} </label>
                                                <textarea 
                                                    :disabled="fieldIsDisabled(component)"
                                                    :id="component.fields[fieldIndex].id"
                                                    :placeholder="component.fields[fieldIndex].placeholder"
                                                    v-model="component.fields[fieldIndex].value"
                                                    rows="2" 
                                                ></textarea>
                                            </sui-form-field>
                                        </div>
                                    
                                        <!-- text -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'text'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <label> {{ component.fields[fieldIndex].title }} </label>
                                                <textarea 
                                                    :disabled="fieldIsDisabled(component)"
                                                    :id="component.fields[fieldIndex].id"
                                                    :placeholder="component.fields[fieldIndex].placeholder" 
                                                    v-model="component.fields[fieldIndex].value" 
                                                ></textarea>
                                            </sui-form-field>
                                        </div>

                                        <!-- dropdown single -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'dropdownSingle'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <label> {{ component.fields[fieldIndex].title }} </label>
                                                <sui-dropdown
                                                    :disabled="fieldIsDisabled(component)"
                                                    :id="component.fields[fieldIndex].id"
                                                    selection
                                                    search
                                                    :options="component.fields[fieldIndex].options"
                                                    :placeholder="component.fields[fieldIndex].placeholder"
                                                    v-model="component.fields[fieldIndex].value"
                                                    @input="$forceUpdate()"
                                                ></sui-dropdown>
                                            </sui-form-field>
                                        </div>
  
                                        <!-- dropdown multiple -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'dropdownMultiple'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <label> {{ component.fields[fieldIndex].title }} </label>
                                                <sui-dropdown
                                                    :disabled="fieldIsDisabled(component)"
                                                    :id="component.fields[fieldIndex].id"
                                                    multiple
                                                    selection
                                                    search
                                                    :options="component.fields[fieldIndex].options"
                                                    :placeholder="component.fields[fieldIndex].placeholder"
                                                    v-model="component.fields[fieldIndex].value"
                                                    @input="$forceUpdate()"
                                                ></sui-dropdown>
                                            </sui-form-field>
                                        </div>

                                        <!-- checkbox -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'checkbox'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <sui-checkbox 
                                                    :disabled="fieldIsDisabled(component)"
                                                    :id="component.fields[fieldIndex].id"
                                                    :label="component.fields[fieldIndex].title" 
                                                    v-model="component.fields[fieldIndex].value"
                                                ></sui-checkbox>
                                            </sui-form-field>
                                        </div>


                                        <!-- radio -->
                                        <div v-if="component.fields[fieldIndex].formElementType == 'radio'">
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <label v-if="component.fields[fieldIndex].title" :for="component.fields[fieldIndex].id"> {{ component.fields[fieldIndex].title }} </label>
                                            </sui-form-field>
                                            <div class="form-field-spacing" v-for="option in component.fields[fieldIndex].options" :key="option.text"> 
                                                <sui-form-field>
                                                    <sui-checkbox radio
                                                        :disabled="fieldIsDisabled(component)"
                                                        :name="component.fields[fieldIndex].id" 
                                                        :label="option.text" 
                                                        :value="option.value" 
                                                        v-model="component.fields[fieldIndex].value"
                                                    />
                                                </sui-form-field>
                                            </div>
                                        </div>
                                                
                                        <!-- message -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'message'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required', createOrEditTemplate && 'message-extra-space')" >
                                                <sui-message style="margin-top: 10px;">
                                                    <sui-message-header> {{ component.fields[fieldIndex].title }} </sui-message-header>
                                                    <td v-html="component.fields[fieldIndex].message.text"></td>
                                                    <sui-message-list v-if="component.fields[fieldIndex].message.bullets.length">
                                                        <sui-message-item 
                                                            v-for="(item, index) in component.fields[fieldIndex].message.bullets" 
                                                            :key="index"
                                                            style="margin-top: -2px; margin-bottom: -2px;"
                                                        > {{ item }} </sui-message-item>
                                                    </sui-message-list>
                                                </sui-message>
                                            </sui-form-field>
                                        </div>

                                        <!-- image -->
                                        <div class="form-field-spacing" v-if="component.fields[fieldIndex].formElementType == 'imageArray'"> 
                                            <sui-form-field :class="classes(component.fields[fieldIndex].required && 'required')">
                                                <label> {{ component.fields[fieldIndex].title }} </label>
                                            </sui-form-field>
                                            <sui-table basic="very" v-if="(component.fields[fieldIndex].value != null && component.fields[fieldIndex].value.length) && !useMobileImageRendering" style="margin-top: -20px;">
                                                <sui-table-row v-for="(image, index) in component.fields[fieldIndex].value" :key="index">
                                                    <sui-table-cell selectable :width="2" @click="onImageClicked(component, groupIndex, componentIndex, fieldIndex, index, image)">
                                                        <div :class="classes('image-thumbnail', fieldIsDisabled(component) && 'pointer-disabled')">
                                                            <img :src="image.imageURL" alt="Billede"/>
                                                        </div>
                                                    </sui-table-cell>
                                                    <sui-table-cell :width="13" @click="onImageClicked(component, groupIndex, componentIndex, fieldIndex, index, image)">
                                                        <span :class="classes('image-description', imageDescriptionMargin, fieldIsDisabled(component) && 'pointer-disabled')">
                                                            <strong v-if="!image.imageFound" style="color: red;">
                                                                Billedet: '{{ image.imagePath }}' blev ikke fundet! <br>
                                                            </strong>
                                                            {{ image.imageText }}
                                                        </span>
                                                    </sui-table-cell>
                                                    <sui-table-cell :width="1">
                                                        <sui-button-group vertical>
                                                            <sui-button
                                                                :disabled="index == 0 || fieldIsDisabled(component)"
                                                                size="mini"
                                                                title="Ryk billede op"
                                                                style="padding: 9.87755px;"
                                                                @click.prevent="handleMoveUpImageInForm(component, fieldIndex, index)"
                                                            ><i class="fa-solid fa-up"></i></sui-button> 
                                                            <sui-button
                                                                :disabled="index == component.fields[fieldIndex].value.length - 1 || fieldIsDisabled(component)"
                                                                size="mini"
                                                                title="Ryk billede ned"
                                                                style="padding: 9.87755px;"
                                                                @click.prevent="handleMoveDownImageInForm(component, fieldIndex, index)"
                                                            ><i class="fa-solid fa-down"></i></sui-button> 
                                                        </sui-button-group>
                                                    </sui-table-cell>
                                                </sui-table-row>
                                            </sui-table>
                                            <sui-table basic="very" v-else-if="(component.fields[fieldIndex].value != null && component.fields[fieldIndex].value.length) && useMobileImageRendering" style="margin-top: -20px;">
                                                <sui-table-row v-for="(image, index) in component.fields[fieldIndex].value" :key="index">
                                                    <sui-table-cell selectable :width="8">
                                                        <sui-button
                                                            floated="right"
                                                            :disabled="index == component.fields[fieldIndex].value.length - 1 || fieldIsDisabled(component)"
                                                            size="mini"
                                                            title="Ryk billede ned"
                                                            style="padding: 9.87755px; margin-top: 5px;"
                                                            @click.prevent="handleMoveDownImageInForm(component, fieldIndex, index)"
                                                        ><i class="fa-solid fa-down"></i></sui-button> 
                                                        <sui-button
                                                            floated="right"
                                                            :disabled="index == 0 || fieldIsDisabled(component)"
                                                            size="mini"
                                                            title="Ryk billede op"
                                                            style="padding: 9.87755px; margin-top: 5px;"
                                                            @click.prevent="handleMoveUpImageInForm(component, fieldIndex, index)"
                                                        ><i class="fa-solid fa-up"></i></sui-button> 
                                                        <div 
                                                            :class="classes('image-thumbnail', 'image-thumbnail-large', fieldIsDisabled(component) && 'pointer-disabled')"
                                                            @click="onImageClicked(component, groupIndex, componentIndex, fieldIndex, index, image)"
                                                        >
                                                            <img :src="image.imageURL" alt="Billede"/>
                                                        </div>
                                                    </sui-table-cell>
                                                    <sui-table-cell :width="8" @click="onImageClicked(component, groupIndex, componentIndex, fieldIndex, index, image)">
                                                        <span :class="classes('image-description', imageDescriptionMargin, fieldIsDisabled(component) && 'pointer-disabled')" v-if="image.imageText != ''">
                                                            <strong v-if="!image.imageFound" style="color: red;">
                                                                Billedet: '{{ image.imagePath }}' blev ikke fundet! <br>
                                                            </strong>
                                                            {{ image.imageText }}
                                                        </span>
                                                    </sui-table-cell>
                                                </sui-table-row>
                                            </sui-table>

                                            <div v-else style="margin-top: -10px; margin-bottom: 20px;">
                                                Ingen billeder tilføjet endnu
                                            </div>
                                            <sui-button
                                                size="mini"
                                                title="Tilføj billede"
                                                :disabled="fieldIsDisabled(component) || disallowMultipleImages(component, fieldIndex)"
                                                @click.prevent="onImageClicked(component, groupIndex, componentIndex, fieldIndex)"
                                            >Tilføj Billede</sui-button> 
                                        </div>
                                    </div>

                                    <edit-template-action-buttons
                                        v-if="createOrEditTemplate && !(groupIndex === 0 && componentIndex === 0)"
                                        :groupLength="group.components.length"
                                        :groupIndex="groupIndex"
                                        :componentIndex="componentIndex"
                                        :onDeleteClicked="() => handleDeleteComponentOnTemplate(groupIndex, componentIndex)"
                                        :onEditClicked="() => handleEditComponentOnTemplate(groupIndex, componentIndex)"
                                        :onCreateClicked="() => handleCreateComponentOnTemplate(groupIndex, componentIndex)"
                                        :onMoveDownClicked="() => handleMoveDownComponentOnTemplate(groupIndex, componentIndex)"
                                        :onMoveUpClicked="() => handleMoveUpComponentOnTemplate(groupIndex, componentIndex)"
                                    ></edit-template-action-buttons>
                                </div>
                            </sui-accordion-content>
                        </sui-accordion>
                    </div>
                    <OnScreenSignaturePad
                        v-if="currentForm && currentForm.formComponentGroups"
                    />

                    <!-- Linked Installations -->
                    <sui-divider horizontal>
                        Relaterede Installationer
                    </sui-divider>

                    <p>Vist liste af alle de installationer der er på aftalen</p> 
                    <a>Følgende skal vises:</a>
                    <li>Installationsnummer</li>
                    <li>Adresse</li>
                    <li>Hvorvidt den er brugerdefineret eller hentet fra EFB</li>
                    <li>Opgaver?</li>

                    <p>Knap i bunden til at tilføje en installtion. Skal åbne en modal</p>

                    </div>
                </sui-form>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button
                    positive 
                    size="mini"
                    title="Gem formular"
                    style="padding: 9.87755px;" 
                    @click="saveForm(true, true)"
                ><i class="fa-solid fa-floppy-disk"></i></sui-button>

                <sui-button
                    negative
                    size="mini"
                    title="Slet formular"
                    style="padding: 9.87755px;" 
                    @click="deleteForm()"
                ><i class="fa-solid fa-trash"></i></sui-button>

                <sui-button
                    size="mini"
                    title="Vis PDF"
                    style="padding: 9.87755px;" 
                    @click="generatePdfPreview()"
                ><i class="fa-solid fa-file-pdf"></i></sui-button>

                <sui-button
                    v-if="showEditButton"
                    size="mini"
                    style="padding: 9.87755px;"
                    title="Redigér"
                    @click="updateState('edit')"
                ><i class="fa-solid fa-pen-to-square"></i></sui-button>
            </sui-modal-actions>
        </sui-modal>

        <edit-template-elements
            :isOpen="openAddOrEditElementModal"
            :data="editElementData"
        ></edit-template-elements>

        <pdf-generator
            :isOpen="openViewPdfFormModal"
            :form="currentForm"
        ></pdf-generator>

        <image-editor
            :isOpen="openImageEditor"
            :form="currentForm"
            :image="activeImage"
            :indices="activeImageIndices"
            :path="imageEditorFilePath"
        ></image-editor>

        <select-sign-method-modal
            :isOpen="openSelectSignMethodModal"
            :activeForm="currentForm || {}"
            @close="handleCloseSelectSignMethodModal"
        ></select-sign-method-modal>

    </div>
</template>
<script>
import EventBus from '@/EventBus.js'
import swal from 'sweetalert'
import { db } from '@/firebase.js'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DataAPI } from '@/lib/DataAPI.js'
import { JSON2HTML } from '../mixins/JSON2HTMLMixin.js'

import UserRoles from '@/lib/Enums/UserRoles.js'
import FormToPDFDtate from '@/modules/FormToPdf/enums/FormToPDFState.js'
import FormToPdfComponents from '@/modules/FormToPdf/enums/FormToPdfComponents.js'
// import draggable from "vuedraggable"

import EditTemplateActionButtons from '@/modules/FormToPdf/components/EditTemplateActionButtons.vue'
import EditTemplateElements from '@/modules/FormToPdf/components/EditTemplateElements.vue'
import pdfGenerator from '@/modules/FormToPdf/components/pdfGenerator.vue'
import ImageEditor from '@/modules/FormToPdf/components/ImageEditor.vue'
import OnScreenSignaturePad from '@/modules/FormToPdf/components/OnScreenSignaturePad.vue'
import SelectSignMethodModal from '@/modules/FormToPdf/components/SelectSignMethodModal.vue'

export default {

    mixins: [
        EventBus, 
        Mixin, 
        DataAPI,
        JSON2HTML,
        db, 
    ],

    components: {
        // draggable,
        EditTemplateActionButtons,
        EditTemplateElements,
        pdfGenerator,
        ImageEditor,
        OnScreenSignaturePad,
        SelectSignMethodModal,
    },

    enums: {
        UserRoles,
        FormToPDFDtate,
        FormToPdfComponents,
    },

    props: {
        isOpen: Boolean,
        project: Object,
        activeForm: Object,
        mode: String,
        activeTemplate: Object,
    },

    data() {
        return {
            state: "opening",
            subState: "loading",
            savedByUser: false,

            // Form Data
            formState: FormToPDFDtate.DRAFT,
            selectedFormTemplate: null,
            currentForm: null,
            currentFormId: null,
            formComponentGroups: [],


            // Template Data
            templates: [],


            // Modal Data
            openAddOrEditElementModal: false,
            openViewPdfFormModal: false,
            openSelectSignMethodModal: false,
            editElementData: {
                mode: "",
                content: null,
                groupIndex: null,
                componentIndex: null,
            },

            // Image Editor Data
            openImageEditor: false,
            activeImageIndices: null,
            activeImage: null,
            imageEditorFilePath: 'FormToPdf',

            // Actions
            actions: [
                { text: "Forespørg Billeder", value: "requestImages" },
                { text: "Meld Klar", value: "markReady" },
                { text: "Sæt On Hold", value: "putOnHold" },
                { text: "Send til Underskrift", value: "sendForSignature" },
                { text: "Afvis Opgaven", value: "rejectTask" },
                { text: "Genåben", value: "reopenTask" },
                { text: "Upload og Luk", value: "uploadAndClose" },
            ]
        }
    },

    computed: {
        user() {
            return this.$root.$children[0].user
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        modalHeader(){
            switch (this.state){
                case "new":
                    return "Opret Ny Formular"
                case "edit new":
                    return "Opret Ny Formular"
                case "view":
                    // TODO: Change to currentForm name
                    return "Vis"
                case "edit":
                    return "Redigér"

                case "editTemplate":
                    return "Redigér Skabelon"
                case "createNewTemplate":
                    return "Opret Ny Skabelon"
                case "opening":
                    return "Åbner..."
                default:
                    return ""
            }
        },

        templateOptions(){
            let options = []
            options = this.templates.map(template => {
                return {
                    text: template.templateName,
                    value: template.id,
                }
            })
            return options
        },

        canSelectTemplate(){
            if (this.state === "new" && this.subState === "select") return true
            if (this.state === "edit new") return true

            return false
        },

        showActions(){
            return this.state == "view" || this.state == "edit" || this.state == "edit new"
        },

        createOrEditTemplate(){
            return this.state == "createNewTemplate" || this.state == "editTemplate"
        },

        showEditButton(){
            return this.state == "view" && this.currentForm && this.currentForm.state != FormToPDFDtate.AWAITING_APPROVAL
        },

        formFieldWidth(){
            if (this.createOrEditTemplate){
                return "ten"
            } else {
                return "sixteen"
            }
        },

        // dragOptions() {
        //     return {
        //         animation: 200,
        //         group: "description",
        //         disabled: false,
        //         ghostClass: "ghost"
        //     }
        // },

        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        imageDescriptionMargin(){
            if (this.currentMedia.type === 'Desktop'){
                return "desktop-image-description"
            }
            return ""
        },

        useMobileImageRendering(){
            return this.currentMedia.type === 'Mobile'
        }
    },

    methods: {
        clickAway(deep = true){

            this.state = "opening"
            this.subState = "loading"
            this.selectedFormTemplate = null
            this.currentForm = null
            
            if (deep && !this.savedByUser && this.currentFormId && this.mode === 'form'){
                this.dataDeleteFormToPdfForm(this.currentFormId)
            }
            this.currentFormId = null

            if (deep){
                EventBus.$emit('event-edit-pdf-form-modal-closing')
            }
        },

        fieldIsDisabled(component){
            if (component.readOnly) return true
            if (this.state != 'edit new' && this.state != 'edit') return true

            return false
        },

        disallowMultipleImages(component, fieldIndex){
            if (component.data.allowMultiple){
                return false
            }

            if (component.fields[fieldIndex].value === null){
                return false
            }

            if (component.fields[fieldIndex].value.length > 0){
                return true
            }

            return false
        },

        async handleDeleteComponentOnTemplate(groupIndex, componentIndex){

            let shouldDelete = await swal({
                title: "Er du sikker?",
                text: "Er du sikker på at du vil slette dette element?",
                icon: "warning",
                buttons: {
                    cancel: "Annullér",
                    delete: "Slet",
                },
                dangerMode: true,
            })

            if (!shouldDelete){
                return
            }

            if (componentIndex === null){
                this.currentForm.formComponentGroups.splice(groupIndex, 1)
            } else {
                this.currentForm.formComponentGroups[groupIndex].components.splice(componentIndex, 1)
            }
        },

        handleEditComponentOnTemplate(groupIndex, componentIndex){
            if (componentIndex === null){
                this.editElementData.mode = 'editComponentGroup'
                this.editElementData.content = this.currentForm.formComponentGroups[groupIndex]
            } else {
                this.editElementData.mode = 'editComponent'
                this.editElementData.content = this.currentForm.formComponentGroups[groupIndex].components[componentIndex]
            }

            this.editElementData.groupIndex = groupIndex
            this.editElementData.componentIndex = componentIndex
            this.openAddOrEditElementModal = true
        },

        async handleCreateComponentOnTemplate(groupIndex, componentIndex){
            let createMode = await swal({
                title: "Opret ny gruppe eller komponent",
                text: "Vælg om du vil oprette en ny gruppe eller et nyt komponent",
                buttons: {
                    cancel: "Annullér",
                    createGroup: "Ny gruppe",
                    createElement: "Nyt komponent",
                },
                icon: "info",
            })

            if (createMode == null){
                return
            }

            if (createMode == "createGroup"){
                this.editElementData.mode = 'createComponentGroup'
            } else if (createMode == "createElement"){
                this.editElementData.mode = 'createComponent'
            }
           
            this.editElementData.groupIndex = groupIndex
            this.editElementData.componentIndex = componentIndex
            this.openAddOrEditElementModal = true
        },

        handleMoveDownComponentOnTemplate(groupIndex, componentIndex){
            if (componentIndex === null){

                // Disallow moving the first group down
                if (groupIndex == 0){
                    return
                }
                this.moveGroupDown(this.currentForm.formComponentGroups, groupIndex)
            } else {

                // Disallow moving the first component of the first group down
                if (groupIndex == 0 && componentIndex == 0){
                    return
                }
                this.moveComponentDown(this.currentForm.formComponentGroups, groupIndex, componentIndex)
            }
            this.$forceUpdate()
        },

        handleMoveUpComponentOnTemplate(groupIndex, componentIndex){
            if (componentIndex === null){

                // Disallow moving the socond group up
                if (groupIndex == 1){
                    return
                }
                this.moveGroupUp(this.currentForm.formComponentGroups, groupIndex)
            } else {

                // Disallow moving the second component of the first group up
                if (groupIndex == 0 && componentIndex == 1){
                    return
                }

                this.moveComponentUp(this.currentForm.formComponentGroups, groupIndex, componentIndex)
            }
            this.$forceUpdate()
        },

        handleMoveUpImageInForm(component, fieldIndex, imageIndex){
            if (imageIndex == 0){
                return
            }

            let temp = component.fields[fieldIndex].value[imageIndex]
            component.fields[fieldIndex].value[imageIndex] = component.fields[fieldIndex].value[imageIndex - 1]
            component.fields[fieldIndex].value[imageIndex - 1] = temp
            this.$forceUpdate()
        },

        handleMoveDownImageInForm(component, fieldIndex, imageIndex){
            if (imageIndex == component.fields[fieldIndex].value.length - 1){
                return
            }

            let temp = component.fields[fieldIndex].value[imageIndex]
            component.fields[fieldIndex].value[imageIndex] = component.fields[fieldIndex].value[imageIndex + 1]
            component.fields[fieldIndex].value[imageIndex + 1] = temp
            this.$forceUpdate()
        },

        togglePageBreakBefore(component){
            component.pageBreakBefore = !component.pageBreakBefore
            this.$forceUpdate()
        },

        toggleAutoPageBreak(){
            this.currentForm.dynamicPageBreak = !this.currentForm.dynamicPageBreak
        },

        updateState(newState){
            console.log("state changed", newState)
            this.state = newState
        },

        updateSubstate(newSubState){
            this.subState = newSubState
        }, 

        async onImageClicked(component, componentGroupIndex, componentIndex, fieldIndex, index = 0, image = null){

            if (this.fieldIsDisabled(component)){
                return
            }

            this.activeImage = image
            this.imageEditorFilePath = `FormToPdf/${this.currentFormId}`
            this.activeImageIndices = {
                componentGroupIndex: componentGroupIndex,
                componentIndex: componentIndex,
                fieldIndex: fieldIndex,
                imageIndex: index,
            }
            this.openImageEditor = true
        },

        accordionHeader(group){
            console.log("Getting accordion header")
            if (group.accordionOpen){
                return ""
            } else {
                return group.headerText
            }
        },

        toggleAccordion(group){
            console.log("Toggling accordion")
            group.accordionOpen = !group.accordionOpen
        },

        async saveForm(clickAway = true, savedByUser = false){
            console.log("Saving form", "state", this.state, "subState", this.subState)

            console.log("Form", this.currentForm)

            let firebaseDoc = this.generateFirestoreDocument(this.currentForm)
            console.log("Form after reduction", firebaseDoc)

            let response = null

            if (this.state === 'createNewTemplate' || this.state === 'editTemplate'){ 
                let docID = null
                if (this.state === 'editTemplate'){
                    docID = this.currentForm.id
                }

                // Create an array of all group ids
                let groupIds = []
                firebaseDoc.formComponentGroups.forEach(group => {
                    groupIds.push(group.id)

                    // Create an array of all component ids
                    let componentIds = []
                    group.components.forEach(component => {
                        componentIds.push(component.id)
                    })
                    group.componentIds = componentIds
                })
                firebaseDoc.groupIds = groupIds
                console.log("Firebase doc", docID, firebaseDoc)

                await this.dataAddOrUpdateFormToPdfTemplate(docID, firebaseDoc)
                this.clickAway(true)
            } else {
                firebaseDoc.documentTitle = firebaseDoc.formComponentGroups[0].components[0].data.text
                firebaseDoc.thumbnail = await this.getFirstImage(firebaseDoc)
                firebaseDoc.state = FormToPDFDtate.getBaseStateFromCode(firebaseDoc.state)

                if (!firebaseDoc.documentTitle || firebaseDoc.documentTitle == ""){
                    firebaseDoc.documentTitle = "Ny tom formular"
                }

                response = await this.dataAddOrUpdateFormToPdfForm(this.currentFormId, firebaseDoc)
                this.savedByUser = savedByUser
                if (clickAway) {
                    this.clickAway(true)
                }
            }
            return response 
        },

        async deleteForm(){

            let shouldDelete = await swal({
                title: "Er du sikker?",
                text: "Er du sikker på at du vil slette denne formular?",
                icon: "warning",
                buttons: {
                    cancel: "Annullér",
                    delete: "Slet",
                },
                dangerMode: true,
            })

            if (!shouldDelete){
                return
            }

            if (this.firebaseUser.Roles.includes(UserRoles.SUPER_ADMIN)){
                let shouldDeletePermanent = await swal({
                    title: "Vil du slette permanent?",
                    text: "Er du sikker på at du vil slette denne formular permanent?",
                    icon: "warning",
                    buttons: {
                        cancel: "Annullér",
                        trash: "Flyt til papirkurv",
                        delete: "Slet permanent",
                    },
                    dangerMode: true,
                })

                if (!shouldDeletePermanent){
                    return
                }

                if (shouldDeletePermanent === "delete"){
                    console.log("Deleting form permanently")
                    await this.dataDeleteFormToPdfForm(this.currentFormId)
                    this.clickAway(true)
                    return
                }
            }

            this.currentForm.state = FormToPDFDtate.CANCELLED
            this.saveForm(true, false)

            console.log("Deleting form trash bin")
        },

        async prePopulateForm(){

            if (this.mode === 'form'){
                console.log("Mode is form")
                if (this.activeForm == null){ // Create new form
                    this.updateState("new")
                    this.updateSubstate("loading")
                    
                    // Bind all templates from database
                    await this.$bind('templates', db.collection('FormToPdfTemplates').orderBy('templateName', 'asc'))

                    this.selectedFormTemplate = null

                    // this.currentForm = {
                    //     state: FormToPDFDtate.DRAFT,
                    //     dynamicPageBreak: true,
                    //     formComponentGroups: [],
                    // }

                    this.updateSubstate("select")
                } else { // Edit form
                    this.savedByUser = true
                    this.currentForm = this.cloneJson(this.activeForm)
                    this.currentForm.id = this.activeForm.id
                    this.currentFormId = this.activeForm.id
                    await this.addFormFieldsToFormDocument(this.currentForm)
                    console.log("Active form", this.currentForm)
                    this.updateState("view")
                    // TODO: Populate form with currentForm data
                    this.updateSubstate("edit")
                }
            }

            if (this.mode === 'template'){
                console.log("Mode is template")

                if (this.activeTemplate === null){
                    this.updateState("createNewTemplate")
                    this.currentForm = await this.generateNewFormTemplate()
                } else {
                    this.updateState("editTemplate")
                    this.currentForm = this.cloneJson(this.activeTemplate)
                }
                this.updateSubstate("edit")
            }

            
        },

        async populateFromTemplate(){
            this.updateSubstate("loading")

            // Find the template
            this.currentForm = this.cloneJson(this.templates.find(template => template.id == this.selectedFormTemplate))
            this.currentForm.templateId = this.selectedFormTemplate
            this.updateFormState(this.currentForm, FormToPDFDtate.DRAFT)

            let linkedProjects = []
            if (this.project != null){
                linkedProjects.push(this.project.id)
            } else {
                linkedProjects.push(this.GLOBAL_PROJECT_ID)
            }
            this.currentForm.linkedProjects = linkedProjects

            // Populate the form with the template data
            await this.addFormFieldsToFormDocument(this.currentForm)
            this.updateState("edit new")
            this.updateSubstate("edit")

            console.log("Current Doc id ", this.currentFormId)
            let response = await this.saveForm(false, false)
            console.log("Response", response)
            if (response != null && typeof response != "undefined"){
                this.currentFormId = response.id
            }

            // this.currentFormId = response.id

            // this.currentForm.id = docID

            console.log("Active form", this.currentForm)
        },

        generatePdfPreview(){
            this.currentForm = this.generateFirestoreDocument(this.currentForm, false)
            console.log("Generating PDF preview")
            this.openViewPdfFormModal = true
        },

        handleAction(action){
            console.log("Handling action", action.value)

            if (action.value === "sendForSignature"){
                console.log("Opening sign method modal");
                this.openSelectSignMethodModal = true;
            }
        },

        actionIsVisible(action){
            // Switch on action
            switch (action.value){
                case "requestImages":
                    return false // Not implemented yet
                case "markReady":
                    return true
                case "putOnHold":
                    return true
                case "sendForSignature":
                    return true
                case "rejectTask":
                    return true
                case "reopenTask":
                    return true
                case "uploadAndClose":
                    return true
                default:
                    return true
            }
        },

        actionIsDisabled(action){
            switch (action.value){
                case "requestImages":
                    return false
                case "markReady":
                switch(this.currentForm.state){
                        case FormToPDFDtate.AWAITING_APPROVAL:
                            return true;
                        case FormToPDFDtate.SIGNED:
                            return true;
                        case FormToPDFDtate.REJECTED:
                            return true;
                        case FormToPDFDtate.EXPIRED:
                            return true;
                        case FormToPDFDtate.DISMISSED:
                            return true;
                        default:
                            return false;
                    }
                case "putOnHold":
                    return false
                case "sendForSignature":
                    switch(this.currentForm.state){
                        case FormToPDFDtate.AWAITING_APPROVAL:
                            return true;
                        case FormToPDFDtate.SIGNED:
                            return true;
                            case FormToPDFDtate.ON_HOLD:
                            return true;
                        case FormToPDFDtate.REJECTED:
                            return true;
                        case FormToPDFDtate.EXPIRED:
                            return true;
                        case FormToPDFDtate.DISMISSED:
                            return true;
                        default:
                            return false;
                    }
                case "rejectTask":
                    return false
                case "reopenTask":
                    switch(this.currentForm.state){
                        case FormToPDFDtate.ON_HOLD:
                            return false;
                        case FormToPDFDtate.REJECTED:
                            return false;
                        case FormToPDFDtate.EXPIRED:
                            return false;
                        case FormToPDFDtate.DISMISSED:
                            return false;
                        default:
                            return true;
                    }
                case "uploadAndClose":
                    switch(this.currentForm.state){
                        case FormToPDFDtate.AWAITING_APPROVAL:
                            return true;
                            case FormToPDFDtate.ON_HOLD:
                            return true;
                        case FormToPDFDtate.REJECTED:
                            return true;
                        case FormToPDFDtate.EXPIRED:
                            return true;
                        case FormToPDFDtate.DISMISSED:
                            return true;
                        default:
                            return false;
                    }
                default:
                    return false
            }
        },

        handleCloseSelectSignMethodModal(mode){
            this.openSelectSignMethodModal = false;

            if (mode == 'deep'){
                this.clickAway();
            }
        },
    },

    beforeMount() {
        EventBus.$on('event-edit-template-elements-modal-click-away', () => {this.openAddOrEditElementModal = false})
        EventBus.$on('event-view-pdf-form-modal-closing', () => {this.openViewPdfFormModal = false})

        EventBus.$on('event-add-component-group-to-template', (payload) => {
            this.insertGroup(this.currentForm.formComponentGroups, payload.groupIndex + 1, payload.accordion, payload.open, payload.headerText)
        })

        EventBus.$on('event-edit-component-group-in-template', (payload) => {
            console.log("Editing group", payload)
            this.currentForm.formComponentGroups[payload.groupIndex].headerText = payload.headerText
            this.currentForm.formComponentGroups[payload.groupIndex].accordion = payload.accordion
            this.currentForm.formComponentGroups[payload.groupIndex].accordionOpen = payload.accordionOpen
        })

        EventBus.$on('event-add-component-to-group-in-template', (payload) => {
            this.insertComponent(this.currentForm.formComponentGroups, payload.groupIndex, payload.componentIndex + 1, payload.data)
        })

        EventBus.$on('event-edit-component-in-template', (payload) => {
            this.currentForm.formComponentGroups[payload.groupIndex].components[payload.componentIndex] = this.executeFunction(
                FormToPdfComponents.getCreateTemplateMethodFromCode(payload.componentType),
                payload.component
            )
        })

        EventBus.$on('event-edit-image-click-away', () => {
            this.openImageEditor = false
            this.activeImage = null
            this.activeImageIndices = null
        })

        EventBus.$on('event-image-editor-update', (payload) => {
            console.log("Updating image", payload)
            this.currentForm.formComponentGroups[payload.indices.componentGroupIndex]
                .components[payload.indices.componentIndex]
                .fields[payload.indices.fieldIndex]
                .value[payload.indices.imageIndex] = payload.imageData

            this.$forceUpdate()
        })

        EventBus.$on('event-image-editor-add', (payload) => {
            console.log("Adding image", payload)

            let formField = this.currentForm.formComponentGroups[payload.indices.componentGroupIndex].
                components[payload.indices.componentIndex].fields[payload.indices.fieldIndex]

            this.addImage(
                formField,
                payload.imageData.imagePath,
                payload.imageData
            ).then(() => {
                this.$forceUpdate()
            })
        })

        EventBus.$on('event-image-editor-delete', (payload) => {
            console.log("Deleting image", payload)
            this.currentForm.formComponentGroups[payload.indices.componentGroupIndex]
                .components[payload.indices.componentIndex]
                .fields[payload.indices.fieldIndex]
                .value.splice(payload.indices.imageIndex, 1)

            if (this.currentForm.formComponentGroups[payload.indices.componentGroupIndex]
                .components[payload.indices.componentIndex]
                .fields[payload.indices.fieldIndex].value.length === 0)
            {
                this.currentForm.formComponentGroups[payload.indices.componentGroupIndex]
                    .components[payload.indices.componentIndex]
                    .fields[payload.indices.fieldIndex].value = null
            }

            this.$forceUpdate()
        })

        // this.prePopulateForm()     
    },

    watch: {
        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        },
    }
}
</script>

<style>
    .actions-buttons {
        margin-top: 5px !important;
    }
    .placeholder-desktop {
        height: 250px;
    }
    .placeholder-mobile {
        height: 150px;
    }
    .headerText {
        font-size: larger;
        font-weight: bold;
    }

    .form-field-spacing {
        margin-top: 3px;
        margin-bottom: 3px;
    }
    .component-spacing {
        /* margin-top: 10px; */
        margin-bottom: 10px;
    }
    .message-extra-space {
        margin-top: 55px !important;
    }
    .accordion-spacing {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    .button-spacing {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .textDraggable {
        margin: 20px;
    }

    .image-thumbnail {
        margin-top: 3px;
        width: 100px;
        height: 100px;
        /* border: 1px solid black; */
        /* margin-right: 10px; */
    }

    .image-thumbnail-large {
        width: 150px !important;
        height: 150px !important;
    }

    .image-thumbnail img {
        width: 100%;
        height: 100%;
        border-radius: 15%;
        object-fit: cover;
    }

    .image-thumbnail:hover {
        cursor: pointer;
    }

    .image-description {
        height: 70px;
        overflow: hidden;
        display: -webkit-box; /* Required for ellipsis */
        -webkit-box-orient: vertical; /* Required for ellipsis */
        -webkit-line-clamp: 3; /* Number of lines to show */
        line-clamp: 3; /* Optional if not supporting Webkit browsers */
        text-overflow: ellipsis;
        white-space: pre-line;
    }

    .desktop-image-description {
        margin-left: -20px;
    }

    /* .tablet-image-description {
        margin-left: -20px;
    } */

    .image-description:hover {
        cursor: pointer;
    }

    .pointer-disabled {
        cursor: default !important;
    }
</style>