<template>
    <div class="login-box" v-if="state!=mainStateName">
        <div class="login-logo">
            <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth-dev.appspot.com/o/assets%2FFiberLAN_logo.svg?alt=media&token=229c6da0-c5a7-4b4b-938f-120ba435f6b2" />
        </div>

        <DataCard class="login-card-body">
            <div style="height: 20px; margin-top: -20px; margin-bottom: 10px;">
                <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fda.svg?alt=media&token=b6f74453-c309-4a38-974e-c1a2516fdabe" @click="changeLang('da')"/>
                <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fen.svg?alt=media&token=563007c2-7298-430d-a390-29d5a453581f" @click="changeLang('en')"/>
            </div>

            <div v-if="state=='verify' || state=='retry'">
                <h3 class="login-box-msg"> {{ headerText }}</h3>
                <p class="login-box-msg"> {{ smsText }} <br/> {{ verifySMSPhone }}</p>

                <div style="display: flex; justify-content: center; margin-bottom: 15px;">
                    <sui-button 
                        class="fiberlan-button" 
                        circular
                        @click.prevent="requestNewHotp"
                    >
                        <i v-if="awaitingHotp" class="fa-solid fa-spinner-scale fa-spin-pulse"></i>
                        <span v-else> {{ resendButtonText }} </span>
                    </sui-button>
                </div>

                <sui-form error>
                    <sui-message error v-if="state=='retry'">
                        <p style="text-align: center;">
                            Forkert kode. Prøv igen, <br>eller gensend SMS-kode.
                        </p>
                    </sui-message>

                    <sui-form-field style="font-family: 'FiberLAN Regular'; font-weight: normal;">
                        <label style="font-family: 'FiberLAN Bold'; font-weight: normal;"> {{ formFieldHeader }} </label>
                        <input
                            type="number"
                            :placeholder="formFieldPlaceholder"
                            v-model="verificationCode"
                            maxlength="10"
                        />
                    </sui-form-field>
                </sui-form>

                <div style="display: flex; justify-content: center; margin-top: 10px;">
                    <sui-button 
                        class="fiberlan-button" 
                        :disabled="enterVerificationCodeButtonDisabled"
                        circular 
                        @click.prevent="verifyHotp"
                    > 
                        <i v-if="awaitingVerification" class="fa-solid fa-spinner-scale fa-spin-pulse"></i>
                        <i v-else class="fa-solid fa-right-to-bracket"></i> 
                    </sui-button>
                </div>
            </div>

            <div v-if="state=='request' && lang=='da'">
                <h3>Indlæser...</h3>
                <p>Vi er ved at indlæse siden. Vent venligst.</p>
            </div>

            <div v-if="state=='request' && lang=='en'">
                <h3>Loading...</h3>
                <p>We are loading the page. Please wait.</p>
            </div>

            <div v-if="state=='notFound' && lang=='da'">
                <h3>404 - Siden blev ikke fundet!</h3>
                <p>Den side du leder efter blev ikke fundet. Prøv at genindlæse siden, eller kontakt support.</p>
            </div>
            <div  v-if="state=='notFound' && lang=='en'">
                <h3>404 - Page not found!</h3>
                <p>The page you are looking for was not found. Try reloading the page, or contact support.</p>
            </div>

            <div v-if="state=='expired' && lang=='da'">
                <h3>Din session er udløbet!</h3>
                <p>Det er ikke længere muligt at se eller underskrive dette dokument.</p>
            </div>
            <div v-if="state=='expired' && lang=='en'">
                <h3>Your session has expired!</h3>
                <p>It is no longer possible to view or sign this document.</p>
            </div>

            <div v-if="state=='signout' && lang=='da'">
                <h3>Du er nu logget ud!</h3>
                <p>Du er nu logget ud af systemet. Du kan nu lukke denne fane.</p>
            </div>
            <div v-if="state=='signout' && lang=='en'">
                <h3>You are now logged out!</h3>
                <p>You are now logged out of the system. You can now close this tab.</p>
            </div>

        </DataCard>
        <div style="height: 10px !important;"></div>
    </div>
</template>

<script>
import { cloudFunctions } from '@/firebase.js'

export default {
    name: 'SignInComponent',

    props: {
            state: {
                type: String,
                required: true
            },
            mainStateName: {
                type: String,
                required: true
            },
            collectionName: {
                type: String,
                required: true
            },

        },

    data() {
        return {

            verifySMSPhone: '',

            lang: 'da',
            signLedgerDocId: '',
            sessionToken: '',
            accessToken: '',

            verificationCode: '',

            awaitingVerification: false,
            awaitingHotp: false,
        }
    },

    computed: {
        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        pdfViewportHeight() {
            return this.currentMedia.type === 'mobile' ? '100vh' : '65vh'
        },

        headerText() {
            return this.lang === 'da' ? 'Velkommen til FiberLAN\'s kundeportal' : 'Welcome to FiberLAN\'s customer portal'
        },

        smsText() {
            return this.lang === 'da' ? 'Vi har nu sendt dig en SMS-kode på' : 'We have now sent you an SMS code on'
        },

        resendButtonText() {
            return this.lang === 'da' ? 'Gensend SMS-kode' : 'Resend SMS code'
        },

        formFieldHeader() {
            return this.lang === 'da' ? 'Indtast SMS-kode' : 'Enter SMS code'
        },

        formFieldPlaceholder() {
            return this.lang === 'da' ? 'SMS-kode' : 'SMS code'
        },

        enterVerificationCodeButtonDisabled() {
            return this.verificationCode.length < 6
        },
    },

    methods: {

        updateState(newState) {
            this.$emit('updateState', newState);
        },

        changeLang(lang) {
            this.lang = lang
            const url = new URL(window.location);
            url.searchParams.set('lang', this.lang);
            window.history.pushState({}, '', url);
        },

        setAccessToken(token) {
            this.accessToken = token;
            const url = new URL(window.location);
            url.searchParams.set('access', token);
            window.history.pushState({}, '', url);
        },

        async requestNewHotp() {
            this.verificationCode = '';
            await this.requestHotp();
        },

        async requestHotp() {
            this.awaitingHotp = true;
            try {
                let apiData = await cloudFunctions.httpsCallable('requestHOTP')({
                    firebaseCollection: this.collectionName, 
                    documentId: this.signLedgerDocId, 
                    sessionToken: this.sessionToken,
                });
                console.log('Received data:', apiData.data);
                this.verifySMSPhone = apiData.data.signerPhone;
                this.updateState('verify');
                this.awaitingHotp = false;


            } catch (error) {
                if (error.code == 'functions/permission-denied'){
                    this.updateState('expired');
                }

                if (error.code == 'functions/not-found'){
                    this.updateState('notFound');
                }

                if (error.code == 'functions/invalid-argument'){
                    this.updateState('notFound');
                }

                console.error('Error code:', error.code);
                console.error('Error message:', error.message);
                console.error('Error details:', error.details);
                this.awaitingHotp = false;
            }
        },

        async verifyHotp() {
            this.awaitingVerification = true;
            try {
                let apiData = await cloudFunctions.httpsCallable('verifyHOTP')({
                    firebaseCollection: this.collectionName, 
                    documentId: this.signLedgerDocId, 
                    sessionToken: this.sessionToken,
                    hotp: this.verificationCode,
                });
                console.log('Received data:', apiData.data);
                this.updateState(this.mainStateName);
                this.setAccessToken(apiData.data.accessToken);
                this.awaitingVerification = false;
            } catch (error) {
                if (error.code == 'functions/permission-denied'){
                    this.updateState('retry');
                    this.verificationCode = '';
                }

                if (error.code == 'functions/not-found'){
                    this.updateState('notFound');
                }

                console.error('Error code:', error.code);
                console.error('Error message:', error.message);
                console.error('Error details:', error.details);

                this.awaitingVerification = false;
            }
        },
    },

    mounted() {
        if (this.state == 'request'){
            this.requestHotp();
        }
    },

    watch: {
        $route: {
            immediate: true,
            handler(newVal) {
                if (newVal.query.lang) {
                    this.lang = newVal.query.lang
                } else {
                    this.lang = 'da'
                }

                if (newVal.query.id) {
                    this.signLedgerDocId = newVal.query.id
                    console.log('signLedgerDocId:', this.signLedgerDocId);
                }

                if (newVal.query.token) {
                    this.sessionToken = newVal.query.token
                    console.log('sessionToken:', this.sessionToken);
                }
            }
        },
    },
}
</script>
<style scoped>
    h3 {
        font-family: 'FiberLAN Bold';
        color: black;
    }

    h4 {
        font-family: 'FiberLAN Bold';
        color: black;
    }

    p {
        font-family: 'FiberLAN Regular';
    }

    li {
        font-family: 'FiberLAN Regular';
        margin-bottom: 7px;
    }

    .login-box {
        align-content: center;
        height: 100svh;
        margin-left: auto;
        margin-right: auto;
    }
    img.logo {
        width: 100%;
    }

    img.main-logo {
        margin-top: 10px;
        margin-bottom: 10px;
        min-width: 150px !important;
        width: 15%;
    }

    img.lang-flag {
        float: right;
        width: 10%;
        max-width: 30px !important;
        margin-left: 10px;
    }

    img.lang-flag:hover {
        cursor: pointer;
        transform: scale(1.125);
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
</style>
<style>
    .customer-portal-login-page {
        /* background-color: #ccb19d; */
        background-image: url('https://fiberlan.dk/wp-content/uploads/2023/04/FiberLAN-lokationer-4-1.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        background-color: var(--fiberlan-orange-light);
    }

    .customer-portal-main-page {
        /* background-color: #ccb19d; */
        background-image: url('https://fiberlan.dk/wp-content/uploads/2023/04/FiberLAN-SOL-kobber.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        /* background-size: cover; */
        background-position: center;
        background-color: white;
    }
</style>
