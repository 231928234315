<template>
    <span>
        <!-- GREY ON HOLD ICON -->
        <span style="padding: 0 3px 0 3px"
            v-if="anyOnHoldTask" 
            :title="`Mindst én opgave på kunden er 'On Hold' (${(anyOnHoldTask.state.reason && anyOnHoldTask.state.reason.value) ? 
                (OnHoldReason.getDesciptionFromCode(anyOnHoldTask.state.reason.value)) : 
                'Ingen årsag valgt'})`"
        >
            <i opt="nestedIcon" class="fas fa-pause" style="color: dimgrey;"></i>
            <sub v-if="anyOnHoldTask.state.reason"><small>
                <i opt="nestedIcon" v-if="anyOnHoldTask && Number(anyOnHoldTask.state.reason.value).toString() == OnHoldReason.CALLER" 
                    class="fas fa-house-user text-primary"></i>

                <i opt="nestedIcon" v-else-if="anyOnHoldTask && Number(anyOnHoldTask.state.reason.value).toString() == OnHoldReason.VENDOR" 
                    class="fas fa-building" style="color: violet"></i>

                <i opt="nestedIcon" v-else-if="anyOnHoldTask && Number(anyOnHoldTask.state.reason.value).toString() == OnHoldReason.PROBLEM" 
                    class="fas fa-exclamation-triangle" style="color: tomato"></i>

                <i opt="nestedIcon" v-else-if="anyOnHoldTask && Number(anyOnHoldTask.state.reason.value).toString() == OnHoldReason.CHANGE" 
                    class="fas fa-sync" style="color: gold"></i>

                <i opt="nestedIcon" v-else-if="anyOnHoldTask && Number(anyOnHoldTask.state.reason.value).toString() == OnHoldReason.REQUEST" 
                    class="fas fa-question" style="color: gold"></i>
            </small></sub>
        </span>

        <!-- INSTALLATION TYPE / STATUS ICON -->
        <!-- All tasks complete icon -->
        <span v-if="showAllTasksCompleteIcon(installation)"> 
            <i class="fas fa-check" title="Alle opgaver er afsluttede" style="color: green;"></i>
        </span>
        <span v-else-if="installation.tasks[0] && (installation.tasks[0].installation ? (insContainsOnlyState(installation, TicketState.CLOSED) || 
            insContainsOnlyState(installation, TicketState.RESOLVED)) : insContainsOnlyState(installation, TaskState.CLOSED_COMPLETE))"> 
            <i class="fas fa-check" title="Alle opgaver er afsluttede" style="color: green;"></i>
        </span>
        <span v-if="installation.tasks[0]">
        
            <!-- A task is (CLOSED INCOMPLETE/CLOSED SKIPPED/CANCELED) icon -->
            <span v-if="installation.tasks[0].installation ? insContainsState(installation, TicketState.CANCELED) : 
                insContainsState(installation, TaskState.CLOSED_INCOMPLETE) || insContainsState(installation, TaskState.CLOSED_SKIPPED)"> 
                <i class="fas fa-times" title="Mindst én opgave på kunden er 'Closed Incomplete', 'Closed Skipped' eller 'Canceled'" style="color: red"></i>
            </span>

            <!-- ORANGE CALL IN ADVANCE ICON -->
            <span v-if="showCallInAdvancePhone(installation)"> 
                <i class="fa-solid fa-phone orange-1" :title="installation.appointment.CallInAdvance.noteBody"></i>
            </span>
            
            <!-- BOLIGFORENING ICON -->
            <span v-if="showEmbIcon(installation)">
                <i class="fa-solid fa-house-building" title="Installation er boligforening" style="color: #007bff"></i>
            </span>

            <!-- PINK FLEX / WAITING LIST CUSTOMER -->
            <span v-if="showCancellationListIconOnInst(installation)"> 
                <i class="fa-kit fa-solid-list-clock pink-1" title="Føj kunden til listen af kunder, vi kan kontakte i tilfælde af afbud."></i>
            </span>

            <!-- ISP Waoo logo -->
            <span v-if="settings.get('showInternetServiceProviderIcon') && getISP(installation) == 'Waoo'">
                <!-- <i title="Dette er Waoo kunde" class="fa-solid fa-earth-europe purple-2"></i> -->
                <i title="Dette er Waoo kunde">
                    <img src="../../../public/assets/icons/Waoo_logo.png" style="width: 15px; height: 15px; margin-top: -1px;">
                </i>
            </span>


            <!-- No CPE-task icon -->
            <!-- <span v-if="showMissingCPETaskIcon && (!insHasTaskWithCode(installation, TaskCode.CPE)) && 
                (!insHasTaskWithCode(installation, TaskCode.BOOKKUNDE)) && (!insHasTaskWithCode(installation, TaskCode.EMPTY))">  
                <i title="Der er ingen CPE-opgave på denne kunde" class="fas fa-times-square" style="color: green;"></i>
            </span> -->
            <!-- Draft appointment icon -->
            <span v-if="bookingIsDraft(installation) && !appointmentIsDraft(installation) && bookingIsDraftOverride()"> 
                <i title="Aftale ikke korrekt synkroniseret med EFB" class="fas fa-calendar-exclamation" style="color: #FFAD00;"></i>
            </span>
            <span v-else-if="bookingIsDraft(installation) && bookingIsDraftOverride()"> 
                <i title="Aftale kladde ikke varslet" class="fas fa-calendar-edit"></i>
            </span>

            <!-- Trouble Ticket icon -->
            <span v-if="settings.get('showTaskTypeIcon') && getCurrentAppointmentType(installation) == AppointmentType.TICKET && instTypeIconOverride()">
                <i title="Dette er en driftsag" class="fa-solid fa-bug" style="color: #FFAD00;"></i>
            </span>

            <!-- Inspection task icon -->
            <span v-if="settings.get('showTaskTypeIcon') && getCurrentAppointmentType(installation) == AppointmentType.INSPECTION && instTypeIconOverride()">
                <i title="Dette er en besigtigelsesopgave" class="fa-solid fa-file-contract purple-2"></i>
            </span>

            <!-- Installation (CPE) icon -->
            <span v-if="settings.get('showTaskTypeIcon') && getCurrentAppointmentType(installation) == AppointmentType.INSTALLATION && instTypeIconOverride()">
                <i title="Dette er en installationsopgave" class="fa-solid fa-earth-europe purple-2"></i>
            </span>

            <!-- Patch task only icon -->
            <span v-if="settings.get('showTaskTypeIcon') && getCurrentAppointmentType(installation) == AppointmentType.PATCH && instTypeIconOverride()"> 
                <i title="Dette er en patch opgave" class="fas fa-plug" style="color: green;"></i>
            </span>

            <!-- Technician icon -->
            <span v-if="settings.get('showTaskTypeIcon') && getCurrentAppointmentType(installation) == AppointmentType.TECHNICIAN && instTypeIconOverride()"> 
                <i title="Dette er en udvidet installation" class="fa-solid fa-router purple-2"></i>
            </span>

            <!-- KS icon -->
             <span v-if="settings.get('showTaskTypeIcon') && getCurrentAppointmentType(installation) == AppointmentType.KSFIBER && instTypeIconOverride()"> 
                <i title="Dette er en KS opgave" class="fa-solid fa-spell-check purple-2"></i>
             </span>

            <!-- Unknown AppointmentType -->
            <span v-if="settings.get('showTaskTypeIcon') && getCurrentAppointmentType(installation) == AppointmentType.UNKNOWN && instTypeIconOverride()"> 
                <i title="Denne installation passer ikke på predifinerede opgavetyper" class="fa-solid fa-question" style="color: tomato;"></i>
            </span>
        </span>

        <!-- SUBTASK STATUS ICONS -->
        <span v-if="subtaskStatusShown && installation.subtasks && !(insHasTaskWithCode(installation, TaskCode.PATCH) || insHasTaskWithCode(installation, TaskCode.UDVID)) && getCurrentAppointmentType(installation) != AppointmentType.TICKET">
            <!-- Fiber blown on installation date (planned) icon -->
            <span v-if="!installation.subtasks.BLOW.state.isPositive && installation.subtasks.BLOW.Deferred && installation.subtasks.BLOW.Deferred.Value" 
                class="fa-stack fa-1x" 
                style="font-size: 0.63em"> 
                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i> 
                <i v-if="!installation.subtasks.BLOW.state.isPositive && installation.subtasks.BLOW.Responsible && installation.subtasks.BLOW.Responsible.Email == user.email" 
                    class="fad fa-wind-warning fa-stack-1x on-calendar" :title="'Kundefiberen skal blæses på dagen\nDu har taget ansvaret'" 
                    style="--fa-primary-color: tomato; --fa-secondary-color: #2185d0;"></i>
                <i v-else-if="!installation.subtasks.BLOW.state.isPositive" 
                    :class="installation.subtasks.BLOW.Responsible ? 'fad fa-wind-warning fa-stack-1x on-calendar' : 'fas fa-wind-warning fa-stack-1x on-calendar'" 
                    :title="installation.subtasks.BLOW.Responsible ? 'Kundefiberen skal blæses på dagen for installation\n'+installation.subtasks.BLOW.Responsible.Name+' har ansvaret' : 
                        'Kundefiberen skal blæses på dagen for installation'" 
                    :style="installation.subtasks.BLOW.Responsible ? '--fa-primary-color: tomato; --fa-secondary-color: darkorange;' : 'color:tomato;'"></i>
            </span>

            <!-- Fiber not blown icon -->
            <i v-else-if="!installation.subtasks.BLOW.state.isPositive && installation.subtasks.BLOW.Responsible && installation.subtasks.BLOW.Responsible.Email == user.email" 
                class="fad fa-wind-warning" :title="'Kundefiberen er ikke blæst\nDu har taget ansvaret'" 
                style="--fa-primary-color: tomato; --fa-secondary-color: #2185d0;"></i>
            <i v-else-if="!installation.subtasks.BLOW.state.isPositive" 
                :class="installation.subtasks.BLOW.Responsible ? 'fad fa-wind-warning' : 'fas fa-wind-warning'" 
                :title="installation.subtasks.BLOW.Responsible ? 'Kundefiberen er ikke blæst\n'+installation.subtasks.BLOW.Responsible.Name+' har ansvaret' : 
                    'Kundefiberen er ikke blæst'" 
                :style="installation.subtasks.BLOW.Responsible ? '--fa-primary-color: tomato; --fa-secondary-color: darkorange;' : 'color:tomato;'"></i>
            
            <!-- Fiber spliced on installation date (planned) icon -->
            <span v-else-if="!installation.subtasks.SPLICE.state.isPositive && installation.subtasks.SPLICE.Deferred && installation.subtasks.SPLICE.Deferred.Value" 
                class="fa-stack fa-1x" style="font-size: 0.63em" 
                :title="installation.subtasks.SPLICE.Responsible ? 'Kundefiberen skal splidses i skabet på dagen for installation\n'+installation.subtasks.SPLICE.Responsible.Name+' har ansvaret' : 
                    'Kundefiberen skal splidses i skabet på dagen for installation'">
                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i>
                <i class="fas fa-compress-alt fa-rotate-45 fa-stack-1x on-calendar" style="color: tomato;"></i>
            </span>

            <!-- Fiber not spliced icon -->
            <i v-else-if="!installation.subtasks.SPLICE.state.isPositive" 
                class="fas fa-compress-alt fa-rotate-45" 
                :title="installation.subtasks.SPLICE.Responsible ? 'Kundefiberen er ikke splidset i skabet\n'+installation.subtasks.SPLICE.Responsible.Name+' har ansvaret' : 
                    'Kundefiberen er ikke splidset i skabet'" 
                style="color: tomato;"></i>
            
            <!-- Patched on installation date (planned) icon -->
            <span v-if="!installation.subtasks.PATCH.state.isPositive && installation.subtasks.PATCH.Deferred && installation.subtasks.PATCH.Deferred.Value" 
                class="fa-stack fa-1x" style="font-size: 0.63em" 
                :title="installation.subtasks.SPLICE.Responsible ? 'Kundefiberen skal patches i teknikhuset på dagen for installation\n'+installation.subtasks.SPLICE.Responsible.Name+' har ansvaret' : 
                    'Kundefiberen skal patches i teknikhuset på dagen for installation'">
                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i>
                <i class="fas fa-plug fa-stack-1x on-calendar" style="color: tomato;"></i>
            </span>

            <!-- Not patched status icon -->
            <span v-else-if="!installation.subtasks.PATCH.state.isPositive">
                <i :title="installation.subtasks.SPLICE.Responsible ? 'Kundefiberen er ikke patchet i teknikhuset\n'+installation.subtasks.SPLICE.Responsible.Name+' har ansvaret' : 
                    'Kundefiberen er ikke patchet i teknikhuset'" 
                class="fas fa-plug" 
                style="color:tomato;"></i>
            </span>
        </span>
        <!-- Visit in vain icon -->
        <span v-if="installation.inVainVisits && installation.inVainVisits.length && installation.inVainVisits.length < 10">
            <i :class="`fa-solid fa-square-${installation.inVainVisits.length}`" 
            :title="`${installation.inVainVisits.length} forgæves besøg`"
            style="color: tomato;"></i>
        </span>
    </span>
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { Bookingmixin } from '../../lib/Bookingmixin'
import TaskState from '../../lib/Enums/TaskState'
import TicketState from '../../lib/Enums/TicketState'
import TaskCode from '../../lib/Enums/TaskCode'
import OnHoldReason from '../../lib/Enums/OnHoldReason'
import EventBus from '../../EventBus'
import AppointmentType from '../../lib/Enums/AppointmentType'

export default {
    name: 'InstallationStatusIcons',
    mixins: [Mixin, DateMixin, Bookingmixin],

    enums: {
        TaskState,
        TicketState,
        TaskCode,
        OnHoldReason,
        AppointmentType,
    },

    props: {
        installation: {
            type: Object,
            required: true
        },
        settings: {
            type: Object,
            required: true
        },
        settingsOverride: {
            type: Object,
            required: false
        }
    },

    data() {
        return{
            showSubtaskStatusDataVar: true,
        }
    },

    methods: {
        showEmbIcon(ins){
            if (ins.appointment && ins.appointment.IsHouseAssociation) return true
            if (ins.appointment && !ins.appointment.IsHouseAssociation) return false

            if (ins.updates && ins.updates.IsHouseAssociation) return true

            return false
        },

        showAllTasksCompleteIcon(ins){
            if (ins.appointment && ins.appointment.AllTasksComplete) return true

            // if (ins.tasks[0]){
            //     let allTasksComplete = (ins.tasks[0].installation ? (this.insContainsOnlyState(ins, TicketState.CLOSED) || 
            //         this.insContainsOnlyState(ins, TicketState.RESOLVED)) : this.insContainsOnlyState(ins, TaskState.CLOSED_COMPLETE))
            //     return allTasksComplete
            // }
            return false
        },

        bookingIsDraftOverride(){
            if (this.settingsOverride?.isDraftIcon) return this.settingsOverride.isDraftIcon.value
            return true
        },

        instTypeIconOverride(){
            if (this.settingsOverride?.instTypeIcon) return this.settingsOverride.instTypeIcon.value
            return true
        },

        showCallInAdvancePhone(ins){
            if (ins.appointment && ins.appointment.CallInAdvance && ins.appointment.CallInAdvance.call){
                let today = new Date().setHours(0,0,0,0)
                let dateFromApp = this.parseDate(ins.appointment.TimeWindowString.split(';')[0]).setHours(0,0,0,0)

                if (dateFromApp <= today || !this.settings.get('showCallInAdvanceIcon')) return true
            }
            return false
        },

        showCancellationListIconOnInst(ins){
            if (this.settingsOverride?.cancellationListIcon) return this.settingsOverride.cancellationListIcon.value

            if (!this.settings.get('showCancellationListIcon')) return false
            if (ins.appointment && ins.appointment.IsOnCancellationList) return true
            if (ins.appointment && !ins.appointment.IsOnCancellationList) return false

            if (ins.updates && ins.updates.IsOnCancellationList) return true

            return false
        },

        getISP(ins){
            if (!ins) return null
            if (ins.tasks && ins.tasks[0].code === TaskCode.TICKET) return null

            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'Fastspeed A/S') return 'FastSpeed'
            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'Waoo A/S') return 'Waoo'
            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'Hiper A/S') return 'Hiper'
            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'Norlys Digital') return 'Norlys'
            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'Telenor') return 'Telenor'
            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'Nuuday A/S') return 'YouSee'
            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'EnergiFyn Bredbånd A/S') return 'EnergiFyn'
            if (ins.tasks[0].serviceOrder.serviceProvider.name == 'Kviknet APS') return 'Kviknet'

            return null
        },
    },

    computed: {
        user() {
            return this.$root.$children[0].user
        },

        anyOnHoldTask(){
            // if (this.showSubtaskStatusDataVar === true || this.showSubtaskStatusDataVar === false){
            //     return null
            // }
            // if (this.installation && this.installation.label == '70249599'){
            //     console.log('re-computing anyOnHoldTask')
            // }
            if (!this.installation || !this.installation.tasks) {
                console.error('InstallationStatusIcons: No installation.tasks')
                return null
            }
            return this.getOnHoldTasks(this.installation.tasks)[0]
        },
        subtaskStatusShown(){
            return this.settings.get('showSubtaskStatus') && this.showSubtaskStatusDataVar
        }
    },

    mounted(){
        EventBus.$on('reload-subtask-status-icons', () => {
            this.showSubtaskStatusDataVar = false
            setTimeout(() => {
                this.showSubtaskStatusDataVar = true
            }, 0)
        })
    }
}
</script>
<style scoped>

@import '@/assets/styles/colours.css';
.fa-calendar-edit {
    color: #FFCC00;
}
.fa-rotate-45 {
    transform: rotate(45deg);
}
.on-calendar {
    margin-top: .2em;
}

 i:not([opt="nestedIcon"]) {
    padding: 0 3px 0 3px;
} 

</style>
