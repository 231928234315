<template>
    <div>
        <TableLoader v-if="loading && !disableLoadingAnimation" :overlay="true" />
        <div v-for="groupKey in sortedFormGroupKeys" :key="groupKey">
            <sui-accordion>
                <span 
                    is="sui-accordion-title" 
                    :active="accordionIsOpen(groupKey)" 
                    style="font-weight: bold;"
                >
                    <div class="form-timeline-accordion">
                        <sui-icon name="dropdown"/>
                        <span :class="groupKeyIsDangerMode(groupKey) ? 'red-font' : ''">
                            {{ headerFromGroupKey(groupKey) }}
                        </span>
                        <span style="float: right; opacity: 0.7;">
                            <!-- Insert additional icons here if necessary -->
                            <span :title="documentCountTitle(groupKey)">
                                {{ allForms[groupKey].length }}
                                <i class="fa-solid fa-file-pdf"></i>
                            </span>
                        </span>
                    </div>
                </span>
                <sui-accordion-content :active="accordionIsOpen(groupKey)" style="padding: 0px;">
                    <table class="table table-striped table-hover table-md timeline-table">
                        <col width="75px"/>
                        <col width="auto"/>
                        <col width="auto"/>
                        <tbody>
                            <tr v-for="(form, index) in allForms[groupKey]" :key="index" @click="formTimelineItemClicked(form)">
                                <td>
                                    <div class="image-thumbnail">
                                        <img :src="form.thumbnail" alt="Billede"/>
                                    </div>
                                </td>
                                <td>
                                    {{ form.documentTitle }} <br>
                                    {{ form.id }} <br>
                                    {{ form.state }}
                                </td>
                                <td>
                                    <span v-if="getDaysPassedSinceStateChange(form.stateUpdatedAt) > 30">
                                        <i title="Denne opgave har ligget stille i mere end en måned!" class="fa-solid fa-triangle-exclamation" style="color: #FFAD00;"></i>
                                    </span>
                                    {{ getDaysPassedSinceStateChangeString(form.stateUpdatedAt) }}
                                </td>
                            </tr>
                        </tbody>

                    </table>


                </sui-accordion-content>
            </sui-accordion>
        </div>


    </div>                             
</template>

<script>
    import EventBus from '@/EventBus.js'
    import TableLoader from '@/components/TableLoader.vue'

    import FormToPDFState from '@/modules/FormToPdf/enums/FormToPDFState.js'

    export default {
        name: 'FormTimeline',
    // mixins: [Mixin, DateMixin, Bookingmixin, DataAPI],

        enums: {
            FormToPDFState,
        },

        components: {
            TableLoader,
        },

        props: {
            loading: {
                type: Boolean,
                required: false,
                default: false
            },
            disableLoadingAnimation: {
                type: Boolean,
                required: false,
                default: false
            },
            project: {
                type: Object,
                required: false,
                default: null
            },
            allForms: {
                type: Object,
                required: true,
            }

        },

        data() {
            return {
                sortAscending: false,
                forms: {
                    '01_rejected': [
                        {
                            name: 'Form 1',
                            date: '2021-01-01',
                        },
                        {
                            name: 'Form 2',
                            date: '2021-01-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-01-03'
                        },
                    ],
                    '02_expired': [
                        {
                            name: 'Form 1',
                            date: '2021-01-01'
                        },
                        {
                            name: 'Form 2',
                            date: '2021-01-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-01-03'
                        },
                    ],
                    '03_reopened': [
                        {
                            name: 'Form 1',
                            date: '2021-01-01'
                        },
                        {
                            name: 'Form 2',
                            date: '2021-01-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-01-03'
                        },
                    ],
                    '04_booked': [
                        {
                            name: 'Form 1',
                            date: '2021-01-01'
                        },
                        {
                            name: 'Form 2',
                            date: '2021-01-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-01-03'
                        },
                    ],
                    '05_requestImages': [
                        {
                            name: 'Form 1',
                            date: '2021-01-01'
                        },
                        {
                            name: 'Form 2',
                            date: '2021-01-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-01-03'
                        },
                    ],
                    '06_onHold': [
                        {
                            name: 'Form 1',
                            date: '2021-01-01'
                        },
                        {
                            name: 'Form 2',
                            date: '2021-01-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-01-03'
                        },
                    ],
                    '07_draft': [
                        {
                            name: 'Form 1',
                            date: '2021-07-01'
                        },
                        {
                            name: 'Form 2',
                            date: '2021-07-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-07-03'
                        },
                        {
                            name: 'Form 4',
                            date: '2021-08-01'
                        },
                    ],
                    '08_ready': [
                        {
                            name: 'Form 5',
                            date: '2021-08-02'
                        },
                        {
                            name: 'Form 6',
                            date: '2021-08-03'
                        },
                    ],
                    '10_signed': [
                        {
                            name: 'Form 7',
                            date: '2021-10-01'
                        },
                        {
                            name: 'Form 8',
                            date: '2021-10-02'
                        },
                        {
                            name: 'Form 9',
                            date: '2021-10-03'
                        },
                    ],
                    '11_dismissed': [
                        {
                            name: 'Form 1',
                            date: '2021-01-01'
                        },
                        {
                            name: 'Form 2',
                            date: '2021-01-02'
                        },
                        {
                            name: 'Form 3',
                            date: '2021-01-03'
                        },
                    ],
                    '12_closed': [
                        {
                            name: 'Form 10',
                            date: '2021-12-01'
                        },
                    ],
                    '09_awaitingApproval': [
                        {
                            name: 'Form 11',
                            date: '2021-12-02'
                        },
                    ],
                },
            }
        },

        computed: {
            getStyle() {
                if (this.componentIndex === null){
                    return {
                        'margin-top': "-12px",
                        height: "30px",
                    }
                }
                return {
                    height: "30px",
                }
            },

            sortedFormGroupKeys() {
                const keys = Object.keys(this.allForms).sort((a,b) => {
                    if (a > b) return this.sortAscending ? -1 : 1
                    if (b > a) return this.sortAscending ? 1 : -1
                    return 0
                })

                return keys
            },
        },

        methods: {
            headerFromGroupKey(groupKey) {
                return FormToPDFState.getDisplayNameFromCode(groupKey)
            },

            documentCountTitle(groupKey) {
                if (this.project && this.project.Name){
                    let returnVal = `${this.allForms[groupKey].length} ${this.project?.Integrations?.formToPDF?.name} ${this.headerFromGroupKey(groupKey) ? this.headerFromGroupKey(groupKey) : ''}`
                    return returnVal
                }

                let returnVal = `${this.allForms[groupKey].length} PDF generator dokument${this.allForms[groupKey].length > 1 ? 'er' : ''} ${this.headerFromGroupKey(groupKey) ? this.headerFromGroupKey(groupKey) : ''}`
                return returnVal
            },

            accordionIsOpen(groupKey) {
                if (groupKey === FormToPDFState.SIGNED) return false
                if (groupKey === FormToPDFState.DISMISSED) return false
                if (groupKey === FormToPDFState.CLOSED) return false

                return true
            },

            groupKeyIsDangerMode(groupKey) {
                if (groupKey === FormToPDFState.REJECTED) return true
                if (groupKey === FormToPDFState.EXPIRED) return true

                return false
            },

            formTimelineItemClicked(form) {
                console.log('formTimelineItemClicked', form)

                EventBus.$emit('form-timeline-item-clicked', form)
            },

            normaliseToDateOnly(date){
                return new Date(date.getFullYear(), date.getMonth(), date.getDate())
            },

            getDaysPassedSinceStateChange(date){
                const today = this.normaliseToDateOnly(new Date())
                const compareDate = this.normaliseToDateOnly(new Date(date))

                const differenceInMilliseconds = today - compareDate
                const millisPerDay = 1000 * 60 * 60 * 24
                const differenceInDays = Math.floor(differenceInMilliseconds / millisPerDay)

                return differenceInDays
            },

            getDaysPassedSinceStateChangeString(date){
                const differenceInDays = this.getDaysPassedSinceStateChange(date)

                if (differenceInDays < 2) return 'En dag siden stateskift'
                return `${differenceInDays} dage siden stateskift`
            }
        }
    }
</script>

<style>
    .image-thumbnail {
        width: 63px;
        height: 63px;
        margin-bottom: 5px;
        /* border: 1px solid black; */
        /* margin-right: 10px; */
    }

    .image-thumbnail img {
        width: 100%;
        height: 100%;
        border-radius: 15%;
        object-fit: cover;
    }

    .form-timeline-accordion {
        margin-top: 10px;
        padding-bottom: 10px;
        margin-left: 6px;
        margin-right: 25px;
    }

    .red-font {
        color: red;
    }

    table.timeline-table {
        width: 100%;
        table-layout: fixed;
    }

    tr.disabled td,
    tr.disabled .text-danger,
    tr.disabled .text-primary {
        color: #acacac !important;
    }

    .timeline-table tbody tr,
    .timeline-table tbody tr td {
        cursor: pointer;
    }

    .card-body.p-0 .table tbody>tr>td:first-of-type {
        padding-right: 0.2rem;
    }

    table.timeline-table tr td {
        padding-bottom: 0.2rem;
    }

    table.timeline-table .address-cell i.fa,
    table.timeline-table .address-cell i.fas,
    table.timeline-table .address-cell i.far {
        margin-right: 2px;
    }

    table.timeline-table .timeframe-cell {
        white-space: nowrap;
    }

    table.timeline-table tr.projectCustom {
        background-color: rgb(255 204 0 / 15%);
        outline-style: auto;
        outline-color: rgb(255 204 0 / 40%);
    }

    table.timeline-table tr.globalCustom {
        background-color: rgb(199 20 26 / 15%);
        outline-style: auto;
        outline-color: rgb(199 20 26 / 40%);
    }

    table.timeline-table tr.highlight {
        background-color: rgb(3 123 228 / 15%) /* 037be4 */
    }


    .myAppointment {
        outline-color: rgb(3 123 228 / 100%) !important;
        outline-style: auto;
    }
    .address-text {
        width: inherit;
        min-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .address-col-standard {
        min-width: 150px;
    }
    .address-col-mobile {
        min-width: 250px;
    }
    .address-col-desktop {
        min-width: 270px;
    }
    .address-cell {
        width: 100%;
        min-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .timeframe-cell {
        min-width: 75px;
    }
    .initials-cell {
        min-width: 55px;
    }

    i {
        padding: 0 3px 0 3px;
    }
    
</style>